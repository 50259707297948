import React, {useState} from 'react'
import Api from "../helpers/_api";
import {useNavigate} from "react-router-dom";

export default function ForgottenPasswordPage() {
    const navigate = useNavigate()

    const [email, setEmail] = useState(null)
    const [message, setMessage] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        Api.post('/api/v1/users/reset-password-link', {email: email})
            .then((res) => {
                setMessage(res.data.message)
            })
    }


    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Mot de passe oublié</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form onSubmit={(e) => handleSubmit(e)} className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                                {message &&
                                    <div className={'text-base leading-tight py-2'}>{message}</div>
                                }
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-red-500 bg-red-500 text-red-50 rounded-md shadow-sm text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Réinitialiser mon mot de passe
                                </button>
                            </div>
                            <div className="sm:col-span-2">
                                <button
                                    type="button"
                                    onClick={() => navigate('/login')}
                                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-red-500 rounded-md shadow-sm text-base font-medium text-white hover:bg-red-50 text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Me connecter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}