import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TunnelCommandMapContainer from "../components/maps/TunnelCommandMapContainer";
import cx from "classnames";
import CommandeRecap from "../components/commande-recap/CommandeRecap";
import Navbar from "../components/common/navbar/Navbar";

function CommandTunnelLayout({ children }) {
  const { currentPage } = useSelector((state) => state.formulaireCommande);

  const [heightPage, setHeightPage] = useState("100vh");

  useEffect(() => {
    const resize = () => {
      const windowH = window.innerHeight;
      const documentH = document.documentElement.scrollHeight;
      const height = Math.max(windowH, documentH);
      setHeightPage(`${height}px`);
    };
    window.addEventListener("resize", resize);
    window.addEventListener("scroll", resize);
    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("scroll", resize);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div
        style={{ paddingTop: 100 }}
        className={cx("command_tunnel--container h-screen", {
          "!hidden": currentPage === "recap",
        })}
      >
        <div className="command_tunnel--map h-full">
          <TunnelCommandMapContainer />
        </div>
        <div className="command_tunnel--form h-full">
          <div className="w-2/3"></div>
          <div id="formulaire-container" className="w-1/3 h-full">
            {children}
          </div>
        </div>
      </div>
      <div
        style={{ height: heightPage, paddingTop: 100 }}
        className={cx("hidden", { "!flex": currentPage === "recap" })}
      >
        <CommandeRecap />
      </div>
    </>
  );
}

export default CommandTunnelLayout;
