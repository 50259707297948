import React, { useEffect, useMemo, useRef, useState } from "react";
import { Recap } from "../footer-formulaire-commande/FooterFormlaierCommande";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import ControlledTextField from "../common/controlled-textfield/controlledTextfield";
import CustomSelect from "../common/custom-select/CustomSelect";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { updatePage } from "../../redux/actions/formulaireCommande/pageAction";
import { saveOrder } from "../../redux/actions/formulaireCommande/orderAction";
import bufferTurf from "turf-buffer";
import pointTurf from "turf-point";
import Api from "../../helpers/_api";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "flowbite-react";
import Alert from "../common/alert/Alert";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { useFormik } from "formik";
import MoreInfo from "../common/more-info/MoreInfo";
import localforage from "localforage";
import "./styles.css";

const genders = [
  {
    code: "null",
    value: null,
    label: "Civilité",
  },
  {
    code: "M",
    value: "monsieur",
    label: "Monsieur",
  },
  {
    code: "Mme",
    value: "madame",
    label: "Madame",
  },
];

const schemaClient = yup.object({
  gender: yup
    .object({
      code: yup.string(),
      value: yup.string(),
      label: yup.string(),
    })
    .default(genders[0]),
  nom: yup.string().required("Champ obligatoire."),
  prenom: yup.string().required("Champ obligatoire."),
  societe: yup.string().required("Champ obligatoire."),
  tel: yup.string().required("Champ obligatoire."),
});

const schemaLogin = yup.object().shape({
  email: yup
    .string()
    .email("Ce n'est pas un adresse email.")
    .required("Champ obligatoire."),
  password: yup
    .string()
    .min(8, "Minimum 8 caractères")
    .max(32, "Maximum 32 caractères")
    .required("Champ obligatoire."),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Veuillez corriger votre confirmation")
    .required("Champ obligatoire."),
  haveReadTerm: yup.boolean().not([false]),
});

export default function CommandeRecap() {
  const {
    currentPage,
    valueTerritoire: {
      typologieAdminData: {
        structureTerritorial,
        territoireCiblage,
        selectedCommunes,
      },
    },
    valueZoneEtudeZan: { selectedPersonalizedZones },
    valueDefinitionEtude: {
      imagerieProvider,
      selectedYears,
      frequency,
      segmentationMos,
    },
    valueSegmentation,
  } = useSelector((state) => state.formulaireCommande);
  const dispatch = useDispatch();

  // refs elements
  const clientFormRef = useRef();

  const auth = useAuth();

  const location = useLocation();
  const [nameProject, setNameProject] = useState(
    !!territoireCiblage
      ? `Suivi du projet ${territoireCiblage.name}`
      : "Donner un nom à votre projet"
  );

  const estimatedPrice = useMemo(() => {
    const surfaceBefore =
      (selectedCommunes || []).reduce(
        (prev, current) =>
          prev + (current?.properties?.surface_commune_meter_square || 0),
        0
      ) *
      selectedYears?.length *
      0.01;

    const surfaceFuture =
      (selectedCommunes || []).reduce(
        (prev, current) =>
          prev + (current?.properties?.surface_commune_meter_square || 0),
        0
      ) *
      frequency *
      0.017;

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
      roundingMode: "floor",
    }).format(15000 + 7500 + surfaceBefore / 1000 + surfaceFuture / 1000);
    // return spacingThousands(parseFloat().toFixed(2)))
  }, [selectedCommunes]);

  const clientForm = useFormik({
    initialValues: {
      gender: genders[0],
      nom: "",
      prenom: "",
      societe: "",
      tel: "",
    },
  });

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      haveReadTerm: false,
    },
  });

  const [userCreationErrors, setUserCreationErrors] = useState([]);

  const { checkSession } = useAuth();
  const [isLogged, setIsLogged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorNameProject, setErrorNameProject] = useState(false);

  useEffect(() => {
    if (isLogged) {
      checkSession();
    }
  }, [isLogged]);

  const [errorsForms, setErrorsForms] = useState({
    gender: [],
    nom: [],
    prenom: [],
    societe: [],
    tel: [],
    email: [],
    password: [],
    passwordConfirmation: [],
  });

  const [isValidForms, setIsValidForms] = useState(false);

  const handleChange = (key, value, formFormik, schema) => {
    const currentValue = { ...formFormik.values, [key]: value };
    formFormik.setValues(currentValue);
    schema
      .validateAt(key, currentValue)
      .then(() => {
        setErrorsForms({ ...errorsForms, [key]: [] });
      })
      .catch((e) => {
        setErrorsForms({ ...errorsForms, [key]: e.errors });
      });
    schema
      .validate(currentValue)
      .then(() => {
        setIsValidForms(true);
      })
      .catch(() => {
        setIsValidForms(false);
      });
  };

  const handleChangeClient = (key) => (value) => {
    handleChange(key, value, clientForm, schemaClient);
  };

  const handleChangeLoginData = (key) => (value) => {
    handleChange(key, value, loginForm, schemaLogin);
  };

  const saveMyOrder = async () => {
    const orderObject = {
      order: {
        name: nameProject,
        imagery_type: imagerieProvider.code,
        default_mos_segmentation: false,
        mos_segments: segmentationMos.name,
        past_years: selectedYears,
        capture_frequency_number: frequency,
        communes_national_codes: selectedCommunes.map((item) =>
          parseInt(item.id)
        ),
        custom_zan_zones_attributes: selectedPersonalizedZones.map((item) => {
          if (item.geometry.type === "Point") {
            const geoJsonCircle = bufferTurf(
              pointTurf([...item.geometry.coordinates].reverse()),
              item.properties.radius,
              "meters"
            );
            return {
              name: item.name,
              geom: {
                type: geoJsonCircle.type,
                properties: geoJsonCircle.properties,
                geometry: geoJsonCircle.geometry,
              },
            };
          }
          return {
            name: item.name,
            geom: {
              type: item.type,
              properties: item.properties,
              geometry: item.geometry,
            },
          };
        }),
      },
    };
    const resp = await dispatch(saveOrder(orderObject));
    if (resp.payload) {
      return true;
    }
    return false;
  };

  const addNewUser = async () => {
    await Api.post("/api/v1/users/", {
      user: {
        gender: clientForm.values.gender.value,
        first_name: clientForm.values.prenom,
        last_name: clientForm.values.nom,
        phone_number: clientForm.values.tel,
        organization: clientForm.values.societe,
        email: loginForm.values.email,
        password: loginForm.values.password,
        password_confirmation: loginForm.values.passwordConfirmation,
      },
    });
  };

  const submit = async () => {
    let errorAddUser = false;
    if (!isLogged) {
      await addNewUser().catch((e) => {
        setUserCreationErrors(e.response.data.message);
        errorAddUser = true;
      });
      if (!errorAddUser) {
        await auth.logIn({
          email: loginForm.values.email,
          password: loginForm.values.password,
        });
      }
    }

    let saved;
    if (!errorAddUser) {
      saved = await saveMyOrder();
    }

    if (saved && !errorAddUser) {
      // remove persist
      localforage.clear();
      setShowModal(true);
    }
  };

  const handleCLickFermer = () => {
    setShowModal(false);
    setTimeout(() => {
      window.location.assign("/");
    }, 100);
  };

  const handleClickEditCommande = () => {
    dispatch(
      updatePage({
        current: "form_territoire",
        next: "form_zone_etude_zan",
      })
    );
  };

  const handleSubmitAllForms = () => {
    if (isValidForms) {
      submit();
    }
  };

  const handleChangeNameProject = (value) => {
    setNameProject(value);
    setErrorNameProject(value.length ? false : true);
  };

  useEffect(() => {
    const token = auth.sessionAuthHeader;
    setIsLogged(token ? true : false);
  }, [location.pathname]);

  useEffect(() => {
    const fixPriceContainer = () => {
      const recapContainer = document.getElementById("recap-container");
      const priceContainer = document.getElementById("price-container");
      if (priceContainer && recapContainer) {
        priceContainer.style.width = `${recapContainer.clientWidth}px`;
      }
    };
    fixPriceContainer();
    window.addEventListener("resize", fixPriceContainer);
    return () => {
      window.removeEventListener("resize", fixPriceContainer);
    };
  }, [currentPage]);

  return (
    <>
      <Modal show={showModal} size="4xl">
        <div className="py-16 px-24 w-full">
          <div className="flex items-center">
            <span className="block w-2 h-2 rounded-full bg-green-400 mr-4"></span>
            <h1 className="font-paytone-one text-2xl">
              Nous avons bien reçu votre demande
            </h1>
          </div>
          <p className="font-poppins font-medium text-xs mt-6">
            Nous vous avons envoyé un lien de confirmation de compte par email
          </p>
          <p className="font-poppins font-semibold text-xs mt-2 mb-4">
            La confirmation de création de votre compte est nécessaire pour vous
            adresser votre devis
          </p>
          <Alert>
            Pensez à vérifier dans vos messages indésirables le cas échéant
          </Alert>
          <p className="font-poppins font-medium text-xs mt-10">
            Vous avez d’autres points importants à partager ?
            <br />
            Vous souhaitez en savoir plus sur les prochains développements, vous
            voulez simplement échanger ?
          </p>
          <div className="flex mt-6">
            <a
              href={"mailto:contact@territoireszan.com"}
              style={{ minWidth: 320 }}
              className="flex items-center justify-between border rounded-md p-6 mr-8"
            >
              <p className="font-poppins font-medium text-sm">
                <strong>Des questions ?</strong>
                <br /> Ecrivez-nous
              </p>
              <img src="/images/envelop.png" alt={""} />
            </a>
            <a
              href={"https://calendly.com/e-attract_py/30min-1?month=2022-08"}
              target={"_blank"}
              style={{ minWidth: 320 }}
              className="flex items-center justify-between border rounded-md p-6"
            >
              <p className="font-poppins font-medium text-sm">
                <strong>Prenons RDV </strong>
                <br />
                En ligne
              </p>
              <img src="/images/calendar.png" alt={""} />
            </a>
          </div>
          <div className="mt-8 flex justify-center">
            <button
              onClick={handleCLickFermer}
              style={{ width: 364, height: 45 }}
              type="button"
              className="bg-danger font-poppins font-medium text-xs text-white rounded-md"
            >
              Fermer
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-row flex-nowrap w-full h-full bg-gray-50">
        <div
          id="recap-container"
          className={classNames(
            "my-recap flex justify-between flex-col bg-white w-1/3 h-full mr-8 overflow-y-auto",
            {
              "pb-24":
                segmentationMos?.name === "Segmentation / MOS - 5 élements",
            }
          )}
        >
          <div className="pl-8 pr-16 w-full">
            <div className="flex items-end pt-6 justify-between">
              <h1 className="font-paytone-one text-2xl">
                Récapitulatif
                <br /> de votre demande
              </h1>
              <button
                onClick={handleClickEditCommande}
                className="font-poppins font-medium text-xs text-danger hover:font-semibold cursor-pointer"
              >
                Modifier
              </button>
            </div>
            <div className="mt-6">
              <ControlledTextField
                placeholder="Nom du projet"
                value={nameProject}
                onChange={handleChangeNameProject}
                withSetEmptyBtn
                errors={errorNameProject && ["Champ obligatoire."]}
              />
            </div>
            <h2 className="font-poppins font-semibold text-lg mt-6 mb-2">
              Territoire d'étude ZAN
            </h2>
            <p className="font-poppins font-medium text-md">
              {structureTerritorial?.label}, {territoireCiblage?.name}
            </p>
            <div className="mt-6 recap-container border-b">
              <Recap />
            </div>
            <div className="mt-6">
              {segmentationMos?.name === "Segmentation / MOS - 5 élements" ? (
                <>
                  <h2 className="font-poppins font-semibold text-lg mb-2">
                    {segmentationMos.name}
                  </h2>
                  {/*{segmentationMosImage.map(({ label, code }) => (*/}
                  {/*  <div key={code} className="flex items-center mb-2">*/}
                  {/*    <img*/}
                  {/*      src={`/images/segmentation/${code}.png`}*/}
                  {/*      className="mr-4"*/}
                  {/*    />*/}
                  {/*    <span className="font-poppins font-normal text-sm">*/}
                  {/*      {label}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*))}*/}
                </>
              ) : (
                <>
                  <h2 className="font-poppins font-semibold text-lg mb-2">
                    {segmentationMos.name}
                  </h2>
                  <p className="font-poppins font-normal text-xs text-gray-600"></p>
                </>
              )}
            </div>
            <div className="mt-6 definition-recap">
              <div>
                <div>Imagerie :</div>
                <div className="flex flex-row">
                  <span className="mr-2">{imagerieProvider?.label}</span>
                  <MoreInfo content="Les imageries sont susceptibles de varier pour les années passées et à venir. Vous recevrez des détails dans le devis récapitulatif par email." />
                </div>
              </div>
              <div>
                <div>Années analysées :</div>
                <div className="flex flex-row">
                  <span className="mr-2">{selectedYears?.join(", ")}</span>
                  <MoreInfo content="Les années passées analysées, en plus des éventuels passages futurs." />
                </div>
              </div>
              {frequency > 0 && (
                <div>
                  <div>Fréquence de passage :</div>
                  <div className="flex flex-row">
                    <span className="mr-2">{frequency} passages par an</span>
                    <MoreInfo content="En fonction de la disponibilité réelle des équipements satellitaires. Vous recevrez des détails dans le devis récapitulatif par email." />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            id="price-container"
            className="footer-recap flex bg-white items-center justify-end py-7 px-6 fixed bottom-0 left-0"
          >
            <div className="flex items-baseline mr-4">
              <span className="font-poppins font-medium text-xs mr-4">
                à partir de
              </span>
              <span className="font-popins font-medium text-2xl">
                {estimatedPrice} HT
              </span>
            </div>
            <MoreInfo content="Ce prix est une estimation et ne fait pas office de devis. Vous recevrez par email un devis détaillé." />
          </div>
        </div>
        <div className="flex justify-center w-2/3">
          <div className="w-full" style={{ maxWidth: 700 }}>
            <form
              ref={clientFormRef}
              className="w-full mt-24"
              onSubmit={clientForm.handleSubmit}
            >
              <h2 className="font-poppins font-semibold text-lg">
                Vos informations
              </h2>
              <div className="recap-info-user-form-container mt-6">
                <CustomSelect
                  keyLabel="label"
                  keyValue="code"
                  options={genders}
                  value={clientForm.values.gender}
                  onChange={handleChangeClient("gender")}
                  errors={errorsForms.gender.length && ["Champ obligatoire."]}
                />
                <ControlledTextField
                  placeholder="Nom"
                  value={clientForm.values.nom}
                  onChange={handleChangeClient("nom")}
                  errors={errorsForms.nom}
                />
                <ControlledTextField
                  placeholder="Prénom"
                  onChange={handleChangeClient("prenom")}
                  value={clientForm.values.prenom}
                  errors={errorsForms.prenom}
                />
                <ControlledTextField
                  placeholder="Société, organisme"
                  onChange={handleChangeClient("societe")}
                  value={clientForm.values.societe}
                  errors={errorsForms.societe}
                />
                <ControlledTextField
                  placeholder="Téléphone"
                  onChange={handleChangeClient("tel")}
                  value={clientForm.values.tel}
                  type={"phone_number"}
                  errors={errorsForms.tel}
                />
              </div>
            </form>
            {!isLogged && (
              <form>
                <h2 className="font-poppins font-semibold text-lg mt-12">
                  Création de votre espace
                </h2>
                <div className="recap-info-user-form-container mt-6">
                  <ControlledTextField
                    placeholder="Email"
                    type="email"
                    onChange={handleChangeLoginData("email")}
                    value={loginForm.values.email}
                    errors={errorsForms.email}
                  />
                  <div></div>
                  <ControlledTextField
                    placeholder="Mot de passe"
                    onChange={handleChangeLoginData("password")}
                    value={loginForm.values.password}
                    type="password"
                    errors={errorsForms.password}
                  />
                  <ControlledTextField
                    placeholder="Confirmation mot de passe"
                    onChange={handleChangeLoginData("passwordConfirmation")}
                    value={loginForm.values.passwordConfirmation}
                    type="password"
                    errors={errorsForms.passwordConfirmation}
                  />
                </div>
                <div className="mt-12">
                  <div className="recap-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleChangeLoginData("haveReadTerm")(e.target.checked)
                      }
                      id="terme"
                    />
                    <label htmlFor="terme">
                      J'ai lu et accepté les&nbsp;
                      <a href="#" className="text-danger hover:font-semibold">
                        CGU
                      </a>
                      &nbsp;ainsi que la&nbsp;
                      <a href="#" className="text-danger hover:font-semibold">
                        politique de confidentialié.
                      </a>
                    </label>
                  </div>
                </div>
                {!isEmpty(userCreationErrors) && (
                  <div className={"inline-flex py-4 items-center"}>
                    <ExclamationCircleIcon
                      className={"w-10 h-10 animate-bounce text-red-500 "}
                    />
                    <ul className={"py-4"}>
                      {userCreationErrors.map((errorMessage) => {
                        return (
                          <li className={"text-red-500 font-bold"}>
                            {errorMessage}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </form>
            )}
            <button
              disabled={!isValidForms || errorNameProject}
              className={classNames(
                "mt-12 bg-danger hover:bg-red-400 w-full text-white font-poppins font-medium text-xs rounded-md",
                {
                  "cursor-not-allowed !bg-red-400":
                    !isValidForms || errorNameProject,
                }
              )}
              style={{ height: 45 }}
              onClick={handleSubmitAllForms}
            >
              Valider les informations et recevoir mon devis
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
