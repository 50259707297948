import { createAsyncThunk } from "@reduxjs/toolkit";
import { formulaireCommandeSlice } from "../../slices/formulaireCommande";
import uniqid from "uniqid";
import pad from "../../../helpers/pad";

export const addNewZonePerso = (newLayer) => (dispatch, getState) => {
  const valueZoneEtudeZan = getState().formulaireCommande.valueZoneEtudeZan;
  const _code = uniqid();
  const personalizedZones = [
    ...valueZoneEtudeZan.personalizedZones,
    { ...newLayer, _code, checked: true },
  ].map((item, key) => {
    if (item._code == _code) {
      return { ...item, name: `ZonePerso ${pad(key + 1)}` };
    }
    return item;
  });
  dispatch(
    formulaireCommandeSlice.actions.set_value_zone_etude_zan({
      ...valueZoneEtudeZan,
      personalizedZones,
      selectedPersonalizedZones: personalizedZones.filter(
        (item) => item.checked
      ),
    })
  );
};

export const updateZonePerso = (layer, _code) => (dispatch, getState) => {
  const valueZoneEtudeZan = getState().formulaireCommande.valueZoneEtudeZan;
  const _personalizedZones = valueZoneEtudeZan.personalizedZones.map((item) => {
    if (item._code === _code) {
      return {
        ...layer,
        name: layer.name || item.name,
        _code: item._code,
        checked: item.checked,
      };
    }
    return item;
  });
  dispatch(
    formulaireCommandeSlice.actions.set_value_zone_etude_zan({
      ...valueZoneEtudeZan,
      personalizedZones: _personalizedZones,
      selectedPersonalizedZones: _personalizedZones.filter(
        (item) => item.checked
      ),
    })
  );
};

export const getZonePersoByCode = createAsyncThunk(
  "formulaireCommande/getZoneByCode",
  (_code, { getState }) => {
    const personalizedZones =
      getState().formulaireCommande.valueZoneEtudeZan.personalizedZones;
    return personalizedZones.find((item) => item._code == _code);
  }
);
