import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

export default function ({ children, type = "info" }) {
  const color = type === "info" ? "text-info" : "";
  const colorIcon = type === "info" ? "text-my-blue" : "";
  const bgcolor = type === "info" ? "bg-background-info" : "";
  return (
    <div
      className={`flex items-start font-poppins py-2 px-4 rounded-md ${bgcolor}`}
    >
      {type === "info" && (
        <div className="mr-2 w-4">
          <ExclamationCircleIcon className={`w-4 ${colorIcon}`} />
        </div>
      )}
      <div className={`text-xs ${color}`}>{children}</div>
    </div>
  );
}
