import { createAsyncThunk } from "@reduxjs/toolkit";
import { formulaireCommandeActions } from "../../slices/formulaireCommande";
import Axios from "../../../helpers/axios";

export const getAddressesByKeyword = createAsyncThunk(
  "getAddressesByKeyword",
  (keyword, { dispatch }) => {
    dispatch(formulaireCommandeActions.set_addresses([]));
    dispatch(formulaireCommandeActions.set_communes([]));
    dispatch(formulaireCommandeActions.set_selected_communes([]));
    return Axios.get(`/v4/territoires_zan/bano_proxy/search?q=${keyword}`)
      .then(({ data: { features } }) => {
        const results = (features || []).map((item) => item?.properties);
        dispatch(formulaireCommandeActions.set_addresses(results));
        return results;
      })
      .catch((e) => {
        console.log(e);
        dispatch(formulaireCommandeActions.set_addresses([]));
        return [];
      });
  }
);
