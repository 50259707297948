import React, { useEffect } from "react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import Alert from "../common/alert/Alert";
import TerritoireCard from "../territoire-card/TerritoireCard";
import FormulaireTypologieAdmin from "../ formulaire-typologie-admin/FormulaireTypologieAdmin";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import FormulaireIsochroneFiler from "../formulaire-isochrone-filter/FormulaireIsochroneFilter";

const territoireOptions = [
  {
    code: "typologie_administrative",
    label: "Par typologie administrative",
    image: "/images/typologie_admin.png",
    enabled: true,
  },
  {
    code: "isochrone",
    label: "Par Isochrones",
    image: "/images/isochrone.png",
    enabled: true,
  },
  {
    code: "import",
    label: "Par import de fichier",
    image: "/images/import.png",
    enabled: false,
  },
];

const TerritoireSelect = ({ onChange, value }) => {
  return (
    <div className="w-full">
      {territoireOptions.map((item) => (
        <TerritoireCard
          key={item.code}
          img={item.image}
          label={item.label}
          enabled={item.enabled}
          selected={item.code === value?.code}
          onClick={() => onChange(item)}
        />
      ))}
    </div>
  );
};

const FormulaireTerritoire = () => {
  const dispatch = useDispatch();
  const { valueTerritoire, returnToEditTerritoire, currentPage } = useSelector(
    (state) => state.formulaireCommande
  );

  const handleOnChange = (key) => (value) => {
    if (key === "territoire") {
      if (valueTerritoire.territoire?.code === value.code) {
        dispatch(
          formulaireCommandeSlice.actions.setReturnToEditTerritoire(false)
        );
      } else {
        dispatch(
          formulaireCommandeSlice.actions.set_value_territoire_territoire(value)
        );
      }
    } else {
      dispatch(
        formulaireCommandeSlice.actions.set_value_territoire({
          ...valueTerritoire,
          [key]: value,
        })
      );
    }
  };

  const handleOnClickEditTerritoire = () => {
    dispatch(formulaireCommandeSlice.actions.setReturnToEditTerritoire(true));
  };

  useEffect(() => {
    currentPage === "form_territoire" &&
      dispatch(
        formulaireCommandeSlice.actions.set_active_btn_next(
          !!valueTerritoire?.typologieAdminData?.selectedCommunes?.length
        )
      );
  }, [currentPage, valueTerritoire?.typologieAdminData?.selectedCommunes]);

  return (
    <div className="w-full p-4 flex h-full flex-col">
      <div>
        <div className="flex flex-nowrap items-center justify-between mb-2">
          <h2 className="font-paytone-one text-xl">Etape 1/3 : Territoire </h2>
          <ChevronUpIcon className="h-8 w-8" />
        </div>
        <Alert type="info">
          Il s'agit de la zone spécifique du Territoire sur laquelle vous
          souhaitez avoir un suivi (historique: 2011-2020 et à venir) de
          l'artificialisation des sols.
        </Alert>
        {(returnToEditTerritoire || !valueTerritoire.territoire) && (
          <div className="w-full">
            <h2 className="mt-10 font-poppins font-semibold text-xs mb-4">
              Choisissez comment sélectionner votre Territoire
            </h2>
            <TerritoireSelect
              value={valueTerritoire.territoire}
              onChange={handleOnChange("territoire")}
            />
          </div>
        )}
        {!returnToEditTerritoire && valueTerritoire.territoire && (
          <div className="flex justify-between items-center mt-4 font-poppins">
            <span className="font-medium text-sm">
              Territoire {valueTerritoire.territoire?.label.toLowerCase()}
            </span>
            <span
              onClick={handleOnClickEditTerritoire}
              className="font-semibold hover:font-bold text-xs text-red-500 cursor-pointer"
            >
              Modifier
            </span>
          </div>
        )}
      </div>
      <div className="grow">
        {!returnToEditTerritoire && valueTerritoire.territoire && (
          <div className="w-full h-full">
            {valueTerritoire.territoire?.code == "typologie_administrative" && (
              <FormulaireTypologieAdmin
                value={valueTerritoire.typologieAdminData}
                onChange={handleOnChange("typologieAdminData")}
              />
            )}
            {valueTerritoire.territoire?.code == "isochrone" && (
              <FormulaireIsochroneFiler />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormulaireTerritoire;
