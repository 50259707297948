import classNames from "classnames";
import React from "react";
import { XCircleIcon } from "@heroicons/react/solid";

export default function ControlledTextField({
  placeholder,
  type = "text",
  name,
  value,
  onChange,
  required = false,
  errors = [],
  withSetEmptyBtn = false,
}) {
  const handleClickSetEmpty = () => {
    onChange && onChange("");
  };

  return (
    <div className="flex flex-col w-full">
      <div className="w-full relative">
        <input
          style={{ height: 45, background: "white" }}
          type={type}
          className={classNames(
            "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-xs font-poppins",
            { "!border-danger": errors.length }
          )}
          placeholder={placeholder}
          value={value}
          name={name}
          required={required}
          onChange={(e) => onChange && onChange(e.target.value)}
        />
        {withSetEmptyBtn && (
          <div
            style={{ top: 10, right: 8 }}
            className="absolute z-50 cursor-pointer"
            onClick={handleClickSetEmpty}
          >
            <XCircleIcon className="w-6 h-6 text-gray-400 hover:text-red-400" />
          </div>
        )}
      </div>
      {errors.length
        ? errors.map((error) => (
            <div key={error} className="font-poppins text-xs text-danger my-1">
              {error}
            </div>
          ))
        : null}
    </div>
  );
}
