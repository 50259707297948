import React from "react";
import "./styles.css";

export default function Navbar() {
  return (
    <nav className="my-navbar flex justify-between items-center px-8">
      <a href="/" className="block">
        <img src="/images/logo.png" alt="logo" />
      </a>
      <div className="content"></div>
    </nav>
  );
}
