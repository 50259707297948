import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import { getZonePersoByCode } from "../../redux/actions/formulaireCommande/zonePersoActions";
import {
  addNewZonePerso,
  updateZonePerso,
} from "../../redux/actions/formulaireCommande/zonePersoActions";
import "leaflet-draw";
import pointTurf from "turf-point";
import bufferTurf from "turf-buffer";
import turfArea from "@turf/area";
import { XCircleIcon } from "@heroicons/react/solid";
import cx from "classnames";

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const PopupContent = ({ area, _code }) => {
  const dispatch = useDispatch();
  const [layerGeoJson, setLayerGeoJson] = useState(null);
  const [textValue, setTextValue] = useState("");

  const handleChangeName = (e) => {
    setLayerGeoJson((state) => ({ ...state, name: e.target.value.trim() }));
    setTextValue(e.target.value);
  };

  const handleClick = () => {
    dispatch(updateZonePerso(layerGeoJson, _code));
    dispatch(formulaireCommandeSlice.actions.set_zone_to_edit(null));
  };

  useEffect(() => {
    const getlayerGeoJson = async (_code) => {
      const { payload } = await dispatch(getZonePersoByCode(_code));
      setLayerGeoJson(payload);
      setTextValue(payload.name);
    };
    getlayerGeoJson(_code);
  }, [_code]);

  return (
    <div style={{ width: 260 }}>
      <div
        style={{ height: 45 }}
        className="flex flex-row flex-nowrap items-center border rounded-md pl-0 pr-2"
      >
        <input
          onChange={handleChangeName}
          type="text"
          className="w-full font-poppins font-medium text-xs border-none"
          value={textValue}
        />
        <span
          className="cursor-pointer"
          onClick={() =>
            handleChangeName({
              target: {
                value: "",
              },
            })
          }
        >
          <XCircleIcon className="h-6 w-6 text-gray-400 hover:text-gray-600" />
        </span>
      </div>
      <p className="font-poppins font-medium text-sm">{area}&nbsp;km²</p>
      <button
        disabled={textValue.length == 0}
        onClick={handleClick}
        className={cx(
          "w-full rounded-md py-4 bg-danger text-white font-poppins font-medium text-sm",
          { "!bg-red-400 cursor-not-allowed": textValue.length == 0 }
        )}
      >
        Sauvegarder la modification
      </button>
    </div>
  );
};

export default function ZonePerso({ layerNeedsPopup }) {
  const [needClear, setNeedClear] = useState(false);
  const [myLayer, setMyLayer] = useState([]);
  const map = useMap();
  const { valueZoneEtudeZan, zoneToEdit } = useSelector(
    (state) => state.formulaireCommande
  );
  const dispatch = useDispatch();

  const handleOnCreatedLayer = (createEl) => {
    const geoJSONLayer = createEl.layer.toGeoJSON();
    dispatch(
      addNewZonePerso({
        ...geoJSONLayer,
        properties: {
          ...geoJSONLayer.properties,
          radius: createEl.layer._mRadius,
          color: getRandomColor(),
        },
      })
    );
  };

  const removeAllCreatedLayer = () => {
    const deleteBtn = document.querySelector(".leaflet-draw-edit-remove");
    const event = new Event("click");
    if (deleteBtn) {
      deleteBtn.dispatchEvent(event);
      setTimeout(() => {
        const clearAll = document.querySelector('[title="Clear all layers"]');
        if (clearAll && needClear) {
          clearAll.dispatchEvent(event);
          setNeedClear(false);
        }
      }, 100);
    }
  };

  const removeAllLayer = () => {
    myLayer.forEach((layer) => {
      map.closePopup();
      map.removeLayer(layer);
    });
  };

  const updateZone = (e, item) => {
    const layer = e.target;
    const geoJSONLayer = layer.toGeoJSON();
    dispatch(
      updateZonePerso(
        {
          ...geoJSONLayer,
          properties: {
            ...geoJSONLayer.properties,
            radius: layer._mRadius,
            color: item.properties.color,
          },
        },
        item._code
      )
    );
  };

  useEffect(() => {
    let _layers = [];

    removeAllLayer();
    removeAllCreatedLayer();

    (valueZoneEtudeZan.personalizedZones || [])
      .filter((item) => item.checked)
      .forEach((item) => {
        let layer = null;

        // create layer by geoJson
        const pane = map.createPane("pane");
        let geoJSON = null;
        if (item.geometry.type === "Point") {
          layer = L.circle([...item.geometry.coordinates].reverse(), {
            radius: item.properties.radius,
            pane,
          });
          geoJSON = bufferTurf(
            pointTurf([...item.geometry.coordinates].reverse()),
            item.properties.radius,
            "meters"
          );
        } else {
          layer = L.polygon(
            [...item.geometry.coordinates].map((coor) =>
              coor.map((item) => [...item].reverse())
            ),
            {
              pane,
            }
          );
          geoJSON = layer.toGeoJSON();
        }

        const area = parseFloat(turfArea(geoJSON) / 1000000).toFixed(2);

        // edit zone
        if (zoneToEdit === item._code) {
          layer.editing.enable();
          layer.on("edit", (e) => {
            setTimeout(() => {
              layer.editing.disable();
            }, 100);
            updateZone(e, item);
          });
          // popup save
          window.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
              dispatch(formulaireCommandeSlice.actions.set_zone_to_edit(null));
            }
          });
        }

        // popup
        layer.addEventListener("click", (e) => {
          layerNeedsPopup &&
            layerNeedsPopup(e, <PopupContent area={area} _code={item._code} />);
        });

        // display zone in the map
        layer.setStyle({
          fillColor: item.properties.color,
          color: item.properties.color,
        });
        layer.addTo(map);
        _layers.push(layer);
      });
    setMyLayer(_layers);
  }, [valueZoneEtudeZan, zoneToEdit]);

  useEffect(() => {
    const triggerEvent = (className) => () => {
      const els = document.getElementsByClassName(className);
      for (var i = 0; i < els.length; i++) {
        els[i].dispatchEvent(new Event("click"));
        setNeedClear(true);
      }
    };

    setTimeout(() => {
      // hidde control
      const elsControl = document.getElementsByClassName("leaflet-control");
      if (elsControl.item(1)) {
        elsControl.item(1).style.display = "none";
      }

      // trigger event
      const addCircleBtn = document.getElementById("add_cirle");
      const addRectangle = document.getElementById("add_rectangle");
      const addPolygone = document.getElementById("add_polygone");
      if (addCircleBtn && addRectangle && addPolygone) {
        addCircleBtn.addEventListener(
          "click",
          triggerEvent("leaflet-draw-draw-circle")
        );
        addRectangle.addEventListener(
          "click",
          triggerEvent("leaflet-draw-draw-rectangle")
        );
        addPolygone.addEventListener(
          "click",
          triggerEvent("leaflet-draw-draw-polygon")
        );
      }
    }, 100);
  }, []);

  return (
    <EditControl
      onCreated={handleOnCreatedLayer}
      draw={{
        polyline: false,
        circlemarker: false,
        marker: false,
      }}
    />
  );
}
