import React, { useEffect, useState } from "react";
import Alert from "../common/alert/Alert";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  formulaireCommandeSlice,
  imagerieProviders,
} from "../../redux/slices/formulaireCommande";
import { updatePage } from "../../redux/actions/formulaireCommande/pageAction";
import CustomSelect from "../common/custom-select/CustomSelect";
import SelectSegmentationMos from "../select-segmentation-mos/SelectSegmentationMos";
import SelectDateInterval from "../select-date-interval/SelectDateInterval";
import { DateTime } from "luxon";
import classNames from "classnames";

export default function FormulaireDefinitionEtude() {
  const [currentYear] = useState(
    DateTime.now().toLocaleString({ year: "numeric" })
  );
  //   const [currentYearSelected, setCurrentYearSelected] = useState(false);
  const dispatch = useDispatch();
  const {
    valueDefinitionEtude,
    currentPage,
    // valueDefinitionEtude: {
    //   imagerieProvider,
    //   selectedYears,
    //   frequency,
    //   segmentationMos,
    // }
  } = useSelector((state) => state.formulaireCommande);

  //   useEffect(() => {
  //     if (!currentYearSelected) {
  //       dispatch(
  //         formulaireCommandeSlice.actions.set_value_definition_etude({
  //           ...valueDefinitionEtude,
  //           frequency: frequency || 12,
  //         })
  //       );
  //     }
  //   }, [currentYearSelected]);

  const handleUpdatePrevForm = (current, next) => () => {
    dispatch(
      updatePage({
        current,
        next,
      })
    );
  };

  const handleOnChange = (key) => (value) => {
    dispatch(
      formulaireCommandeSlice.actions.set_value_definition_etude({
        ...valueDefinitionEtude,
        [key]: value,
      })
    );
  };

  const handleChangeFrequency = (e) => {
    dispatch(
      formulaireCommandeSlice.actions.set_value_definition_etude({
        ...valueDefinitionEtude,
        //frequency: currentYearSelected ? parseInt(e.target.value) : null,
        frequency: parseInt(e.target.value),
      })
    );
  };

  useEffect(() => {
    if (
      currentPage === "definition_etude" &&
      valueDefinitionEtude.selectedYears.length
    ) {
      dispatch(formulaireCommandeSlice.actions.set_next_page("recap"));
      dispatch(formulaireCommandeSlice.actions.set_active_btn_next(true));
    } else {
      if (currentPage === "definition_etude") {
        dispatch(formulaireCommandeSlice.actions.set_active_btn_next(false));
      }
    }
  }, [valueDefinitionEtude, currentPage]);

  return (
    <div>
      <div className="flex flex-nowrap justify-between items-center border-b bg-white py-6 px-4">
        <div className="flex justify-between w-full items-center">
          <h2 className="font-paytone-one text-xl">Etape 1/3 : Territoire</h2>
          <div
            onClick={handleUpdatePrevForm(
              "form_territoire",
              "form_zone_etude_zan"
            )}
            className="font-poppins font-medium hover:font-semibold text-danger text-xs cursor-pointer px-8"
          >
            Modifier
          </div>
        </div>
        <ChevronDownIcon className="h-8 w-8" />
      </div>
      <div className="flex flex-nowrap justify-between items-center border-b bg-white py-6 px-4">
        <div className="flex justify-between w-full items-center">
          <h2 className="font-paytone-one text-xl">
            Etape 2/3 : Zone d’étude ZAN
          </h2>
          <div
            onClick={handleUpdatePrevForm(
              "form_zone_etude_zan",
              "definition_etude"
            )}
            className="font-poppins font-medium hover:font-semibold text-danger text-xs cursor-pointer px-8"
          >
            Modifier
          </div>
        </div>
        <ChevronDownIcon className="h-8 w-8" />
      </div>
      <div className="px-4 pt-4">
        <div className="flex flex-nowrap items-center justify-between mb-2">
          <h2 className="font-paytone-one text-xl">
            Etape 3 : Définition étude
          </h2>
          <ChevronUpIcon className="h-8 w-8" />
        </div>
        <Alert type="info">
          Définissez les années d'observation souhaitées entre 2011 et 2022 pour
          établir votre référence ZAN et votre fréquence d'observation annuelle
          pour les prochaines années.
        </Alert>
        <div className="mt-4">
          <SelectSegmentationMos
            onChange={handleOnChange("segmentationMos")}
            value={valueDefinitionEtude.segmentationMos}
          />
        </div>
        <div className="mt-4">
          <CustomSelect
            placeholder=""
            keyLabel="label"
            keyValue="code"
            onChange={handleOnChange("imagerieProvider")}
            options={imagerieProviders}
            value={valueDefinitionEtude.imagerieProvider}
          />
        </div>
        <div className="mt-4">
          <label className="block font-poppins font-medium text-sm ml-3 mb-2">
            Sélection des années souhaitées
          </label>
          <SelectDateInterval
            min={2011}
            max={parseInt(currentYear)}
            value={valueDefinitionEtude.selectedYears}
            // setCurrentYearSelected={setCurrentYearSelected}
            onChange={handleOnChange("selectedYears")}
          />
        </div>
        <div
          className={classNames(
            // { "opacity-30 cursor-not-allowed": !currentYearSelected },
            "mt-4"
          )}
        >
          <label className="block font-poppins font-medium text-sm ml-3 mb-2">
            Fréquence du suivi à partir de {currentYear}
          </label>
          <select
            style={{ height: 45 }}
            className={classNames(
              //   { "opacity-30 cursor-not-allowed": !currentYearSelected },
              "border-gray-200 w-full bg-white rounded-md font-poppins font-medium text-xs mb-8"
            )}
            onChange={handleChangeFrequency}
            value={valueDefinitionEtude.frequency}
            // disabled={!currentYearSelected}
          >
            <option value={0}>Aucun passage</option>
            {new Array(48).fill(0).map((_, key) => (
              <option key={key} value={key + 1}>
                {key + 1} passage{key + 1 > 1 ? "s" : ""} / an
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
