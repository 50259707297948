import { createAsyncThunk } from "@reduxjs/toolkit";
import { formulaireCommandeSlice } from "../../slices/formulaireCommande";
import Axios from "../../../helpers/axios";

export const getTerritoiresDuCiblage = createAsyncThunk(
  "formulaireCommande/getTerritoiresDuCiblage",
  async ({ keyword, type = "epci" }, { dispatch }) => {
    try {
      const { data } = await Axios.get(
        `/v4/territoires_zan/${type}_search?q=${keyword}`
      );
      dispatch(
        formulaireCommandeSlice.actions.set_territoire_du_ciblage(
          data.data.map(({ attributes, type, id }) => ({
            code: id,
            name: attributes.name,
            type: type,
          }))
        )
      );
    } catch (e) {
      dispatch(formulaireCommandeSlice.actions.set_territoire_du_ciblage([]));
    }
  }
);
