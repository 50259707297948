import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../../helpers/_api";

export const saveOrder = createAsyncThunk(
  "formulaireCommande/saveOrder",
  async (payload) => {
    const resp = await Api.post("/api/v1/orders", payload);
    if (resp.status === 200) {
      return resp;
    }
    return null;
  }
);
