import { createAsyncThunk } from "@reduxjs/toolkit";
import { formulaireCommandeSlice } from "../../slices/formulaireCommande";
import Axios from "../../../helpers/axios";

export const getEpcis = createAsyncThunk(
  "formulaireCommande/getEpcis",
  async (code, { dispatch }) => {
    Axios.get(
      `/v4/territoires_zan/region/communes_list?national_code=${code}`
    ).then(({ data }) => {
      dispatch(
        formulaireCommandeSlice.actions.set_communes_without_layer(
          data?.features || []
        )
      );
    });
    dispatch(formulaireCommandeSlice.actions.set_national_code(code));
    return await Axios.get(
      `/v4/territoires_zan/region/epci?national_code=${code}`
    ).then(({ data }) => {
      dispatch(formulaireCommandeSlice.actions.set_epci(data?.features || []));
      return data;
    });
  }
);
