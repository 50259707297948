import React, { useEffect, useState } from "react";
import cx from "classnames";

// const minMaxYear = {
//   max: 2022,
//   min: 2011,
// };

export default function SelectDateInterval({
  min,
  max,
  value,
  onChange,
  // setCurrentYearSelected,
}) {
  const [selected, setSelected] = useState([]);

  const handleSelectYear = (year) => () => {
    const _selected = [
      ...selected.filter((item) => item !== year),
      selected.find((item) => item === year) ? undefined : year,
    ]
      .filter((item) => item !== undefined)
      .sort((a, b) => a - b);
    onChange && onChange(_selected);
    setSelected(_selected);
  };

  useEffect(() => {
    if (value) {
      //setCurrentYearSelected(value.indexOf(2020) !== -1);
      setSelected(value);
    }
  }, [value]);

  return (
    <div className="flex w-full flex-wrap">
      {new Array(max - min + 1).fill(0).map((_, key) => (
        <div
          key={min + key}
          style={{ width: 60 }}
          className={cx(
            "font-poppins font-medium text-xs border p-4 mr-2 mb-2 bg-white rounded-md cursor-pointer hover:bg-gray-50",
            { "!border-danger": selected.includes(min + key) },
            // {
            //   "!bg-min-max-year hover:!bg-blue-200":
            //     min + key === min || min + key === max,
            // }
          )}
          onClick={handleSelectYear(min + key)}
        >
          {min + key}
        </div>
      ))}
    </div>
  );
}
