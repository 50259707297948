import React, {useState} from 'react'
import {Switch} from '@headlessui/react'
import {useNavigate} from "react-router-dom";
import Api from "../helpers/_api";
import {isEmpty} from "lodash";
import {ExclamationCircleIcon} from "@heroicons/react/solid";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SignupPage() {
    const [agreed, setAgreed] = useState(false)

    const [errors, setErrors] = useState([])
    const navigate = useNavigate()


    const [userFormData, setUserFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        organization: '',
        email: '',
        password: '',
        password_confirmation: '',
    })


    function handleSubmit(event) {
        event.preventDefault()

        Api.post('/api/v1/users/', {
            user: userFormData
        }).then((res) => {
            navigate('/login', {replace: true})
        }).catch(({response: {data: {message}}}) => {
            setErrors(message)
        })
    }

    return (
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
            <div className="relative max-w-xl mx-auto">
                <svg
                    className="absolute left-full transform translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
                </svg>
                <svg
                    className="absolute right-full bottom-0 transform -translate-x-1/2"
                    width={404}
                    height={404}
                    fill="none"
                    viewBox="0 0 404 404"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="85737c0e-0916-41d7-917f-596dc7edfa27"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor"/>
                        </pattern>
                    </defs>
                    <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"/>
                </svg>
                <div className="text-center">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Créer votre compte</h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                        Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus
                        arcu.
                    </p>
                </div>
                <div className="mt-12">
                    <form onSubmit={(e) => handleSubmit(e)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Prénom
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            first_name: e.target.value,
                                        }));
                                    }}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Nom
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            last_name: e.target.value,
                                        }));
                                    }}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            email: e.target.value,
                                        }));
                                    }}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Entreprise
                            </label>
                            <div className="mt-1">
                                <input
                                    id="organization"
                                    name="organization"
                                    type="text"
                                    autoComplete="company"
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            organization: e.target.value,
                                        }));
                                    }}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                                Numero de téléphone
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="phone-number"
                                    id="phone-number"
                                    autoComplete="tel"
                                    className="py-3 px-4 block w-full focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            phone_number: e.target.value,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Mot de passe
                            </label>
                            <div className="mt-1">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    autoComplete='new-password'
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            password: e.target.value,
                                        }));
                                    }}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Confirmation du mot de passe
                            </label>
                            <div className="mt-1">
                                <input
                                    type="password"
                                    name="password_confirmation"
                                    id="password_confirmation"
                                    autoComplete='new-password'
                                    onChange={(e) => {
                                        setErrors([])
                                        setUserFormData((prevState) => ({
                                            ...prevState,
                                            password_confirmation: e.target.value,
                                        }));
                                    }}
                                    className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <Switch
                                        checked={agreed}
                                        onChange={setAgreed}
                                        className={classNames(
                                            agreed ? 'bg-red-600' : 'bg-gray-200',
                                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                                        )}
                                    >
                                        <span className="sr-only">Agree to policies</span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                agreed ? 'translate-x-5' : 'translate-x-0',
                                                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                            )}
                                        />
                                    </Switch>
                                </div>
                                <div className="ml-3">
                                    <p className="text-base text-gray-500">
                                        En cochant cette case vous acceptez les{' '}
                                        <a href="#" className="font-medium text-gray-700 underline">
                                            conditions de confidentialité
                                        </a>{' '}
                                        et{' '}
                                        <a href="#" className="font-medium text-gray-700 underline">
                                            la politique de cookies
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                        {!isEmpty(errors) && (
                            <div className={'sm:col-span-2 inline-flex align-middle items-center'}>
                                <div>
                                    <ExclamationCircleIcon className={'w-8 h-8 text-red-500 animate-pulse'}/>
                                </div>
                                <ul>
                                    {errors.map((error) => {
                                        return <li>{error}</li>
                                    })}
                                </ul>
                            </div>
                        )}

                        <div className="sm:col-span-2">
                            <button
                                type="submit"
                                className={classNames(isEmpty(errors) && agreed ? 'bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500' : 'bg-red-100 cursor-not-allowed',
                                    "w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white")}
                                disabled={!isEmpty(errors) && agreed}
                            >
                                Créer mon compte
                            </button>
                        </div>
                        <div className="sm:col-span-2">
                            <button
                                type="button"
                                onClick={() => navigate('/login')}
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-red-500 rounded-md shadow-sm text-base font-medium text-white hover:bg-red-50 text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Me connecter
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
