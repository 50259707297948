import React, { useState } from "react";
import CustomAutocomplete from "../common/custom-autocomplete/CustomAutocomplete";
import {
  TRANSPORTMODE,
  TRANSPORT_DURATION_MIN,
  TRANSPORT_DURATION_MAX,
} from "../../constants/defaultValues";
import { useDispatch, useSelector } from "react-redux";
import SliderInput from "../common/slider-input/SliderInput";
import { formulaireCommandeActions } from "../../redux/slices/formulaireCommande";
import { getAddressesByKeyword } from "../../redux/actions/formulaireCommande/isochroneActions";
import useDebounce from "../../hooks/useDebounce";
import { getCommunesByIsochrone } from "../../redux/actions/formulaireCommande/communeActions";
import { CommunesSelect } from "../ formulaire-typologie-admin/FormulaireTypologieAdmin";

const TransportMode = ({ value, onChange, name }) => {
  const handleChange = (e) => {
    onChange &&
      onChange(
        TRANSPORTMODE.find((item) => item.value === e.target.value),
        name
      );
  };

  return (
    <>
      <label className="text-sm">Mode de transport</label>
      <div className="mt-1 flex justify-between">
        {TRANSPORTMODE.map(({ label, value: valueRadio }) => (
          <label key={valueRadio} className="block text-sm">
            <input
              id={value}
              name="transportMode"
              type="radio"
              checked={value?.value === valueRadio}
              value={valueRadio}
              onChange={handleChange}
            />
            <span className="ml-2 text-sm">{label}</span>
          </label>
        ))}
      </div>
    </>
  );
};

const FormulaireIsochroneFiler = () => {
  const {
    addresses,
    valueIsochroneFilter,
    valueTerritoire: {
      typologieAdminData: { selectedCommunes },
    },
    communes,
    loading,
  } = useSelector((state) => state.formulaireCommande);
  const dispatch = useDispatch();

  const handleChange = (value, name) => {
    dispatch(
      formulaireCommandeActions.set_value_isochorone({
        ...valueIsochroneFilter,
        [name]: value,
      })
    );
  };

  const handleChangeSelectedCommunes = (communes) => {
    dispatch(formulaireCommandeActions.set_selected_communes(communes));
  };

  const handleChangeAddressKeyword = (keyword) => {
    dispatch(getAddressesByKeyword(keyword));
  };

  useDebounce(
    () => {
      if (valueIsochroneFilter.isoChroneAddress) {
        dispatch(getCommunesByIsochrone());
      }
    },
    [valueIsochroneFilter],
    500
  );

  return (
    <div className="h-full flex flex-col">
      <div>
        <div className="mt-4">
          <label className="text-sm">Isochrone autour d'une adresse</label>
          <CustomAutocomplete
            placeholder="Indiquez une adresse"
            keyLabel="label"
            keyValue="id"
            onChange={handleChange}
            onChangeInputValue={handleChangeAddressKeyword}
            options={addresses}
            value={valueIsochroneFilter.isoChroneAddress}
            name="isoChroneAddress"
            serverSide
          />
        </div>
        <div className="mt-4">
          <TransportMode
            name="transportMode"
            onChange={handleChange}
            value={valueIsochroneFilter.transportMode}
          />
        </div>
        <div className="mt-4">
          <label className="text-sm">Temps de transport maximum</label>
          <div className="flex items-center">
            <div className="flex-grow">
              <SliderInput
                min={TRANSPORT_DURATION_MIN}
                max={TRANSPORT_DURATION_MAX}
                step={1}
                onChange={handleChange}
                value={valueIsochroneFilter.transportDuration}
                name="transportDuration"
              />
            </div>
            <div className="ml-2 text-sm">min</div>
          </div>
        </div>
      </div>
      <div className="mt-5 grow h-0">
        {loading?.communes ? (
          <div className="text-sm font-medium">Chargement en cours...</div>
        ) : (
          <CommunesSelect
            onChange={handleChangeSelectedCommunes}
            options={communes || []}
            value={selectedCommunes}
          />
        )}
      </div>
    </div>
  );
};

export default FormulaireIsochroneFiler;
