import React, { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import cx from "classnames";
import "./styles.css";

export default function CustomSelect({
  placeholder,
  options,
  value,
  keyValue,
  keyLabel,
  onChange,
  errors = [],
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setTimeout(() => {
      setOpen(!open);
    }, 100);
  };

  useEffect(() => {
    const closeSelect = () => {
      setOpen(false);
    };
    window.addEventListener("click", closeSelect);
    return () => {
      window.removeEventListener("click", closeSelect);
    };
  }, []);

  return (
    <div className="flex w-full flex-col">
      <div
        className={cx(
          "relative custom-select-container border rounded-md bg-white cursor-pointer",
          {
            "border-danger": errors.length,
          }
        )}
        onClick={handleClick}
      >
        <div className="flex items-center justify-between h-full px-4">
          <span className="font-medium text-xs font-poppins">
            {value ? value[keyLabel] : placeholder}
          </span>
          {open ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </div>
        <div
          className={cx(
            { hidden: !open },
            { block: open },
            "custom-select-item-container absolute z-50 top-[49px] left-0 border rounded-md overflow-hidden bg-white w-full"
          )}
        >
          {options.map((item) => (
            <div
              onClick={() => onChange && onChange(item)}
              className="font-normal w-full text-xs font-poppins hover:bg-gray-100 p-3 border-b"
              key={item[keyValue]}
            >
              {item[keyLabel]}
            </div>
          ))}
        </div>
      </div>
      {errors.length
        ? errors.map((item) => (
            <div key={item} className="font-poppins text-danger text-xs my-1">
              {item}
            </div>
          ))
        : null}
    </div>
  );
}
