import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import cx from "classnames";
import { updatePage } from "../../redux/actions/formulaireCommande/pageAction";

export const segmentationMos = [
  {
    code: "asphalte",
    label: "Asphalte (routes et parking)",
  },
  {
    code: "batiment",
    label: "Bâtiments",
  },
  {
    code: "terre_nue",
    label: "Terre nue",
  },
  {
    code: "eau",
    label: "Eau",
  },
  {
    code: "vegetation",
    label: "Végétation",
  },
];

export default function FormulaireSegmentation() {
  const { valueDefinitionEtude, valueSegmentation } = useSelector(
    (state) => state.formulaireCommande
  );
  const dispatch = useDispatch();

  const handleUpdatePrevForm = () => {
    dispatch(
      updatePage({
        current: "definition_etude",
        next: "segmentation",
      })
    );
  };

  const handleChange = (value) => () => {
    dispatch(formulaireCommandeSlice.actions.set_value_segmentation(value));
  };

  return (
    <div className="mx-4">
      <div className="flex justify-between items-center mt-6">
        <span className="font-poppins font-semibold text-lg">
          Segmentation / MOS
        </span>
        <span
          onClick={handleUpdatePrevForm}
          className="font-poppins font-medium hover:font-semibold cursor-pointer text-sm text-danger"
        >
          Modifier
        </span>
      </div>
      <div className="mt-8">
        <div
          onClick={handleChange("seleleted_segmentations")}
          className={cx(
            "p-6 border rounded-md hover:bg-white mb-6 cursor-pointer",
            { "border-danger": valueSegmentation === "seleleted_segmentations" }
          )}
        >
          <label className="block font-poppins font-medium text-md mb-4">
            MOS Segmentation 5 classes
          </label>
          {segmentationMos.map(({ code, label }) => (
            <div key={code} className="flex items-center mb-3">
              <img src={`/images/segmentation/${code}.png`} alt={code} />
              <span className="ml-2 font-poppins font-normal text-xs">
                {label}
              </span>
            </div>
          ))}
        </div>
        <div
          onClick={handleChange("personnalize_segmentation")}
          className={cx("p-6 border rounded-md hover:bg-white cursor-pointer", {
            "border-danger": valueSegmentation === "personnalize_segmentation",
          })}
        >
          <label className="font-poppins font-medium text-md">
            Segmentation sur mesure
          </label>
          <p className="font-poppins text-xs font-normal text-gray-500">
            Selectionnez cette option pour une segmentation sur mesure. Nous
            vous contacterons pour définir ensemble votre besoin.
          </p>
        </div>
      </div>
    </div>
  );
}
