import React from "react";
import CommandTunnelLayout from "../layouts/CommandTunnelLayout";
import FormulaireCommandeTunnel from "../components/formulaire-commande-tunnel/FormulaireCommandeTunnel";

function Home() {
  return (
    <CommandTunnelLayout>
      <FormulaireCommandeTunnel />
    </CommandTunnelLayout>
  );
}

export default Home;
