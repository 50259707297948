import React, { useCallback, useEffect } from "react";
import _, { uniqueId } from "lodash";
import GeoJson from "./GeoJson";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import { useMap } from "react-leaflet";
import { checkPropTypes } from "prop-types";

function GeoJsonContainer({ layers, layerNeedPopup, needFitBound }) {
  const [layerToShow, setLayerToShow] = useState([]);
  const { valueTerritoire, currentPage, communesWithoutLayer, communes, epci } =
    useSelector((state) => state.formulaireCommande);
  const dispatch = useDispatch();

  const map = useMap();

  const [selectedMunicipalitiesIds, setSelectedMunicipalitiesIds] = useState(
    []
  );

  // communes switch select
  const handleChangeEnable = useCallback(
    (checked, id) => {
      let _layer = [];
      if (
        valueTerritoire.typologieAdminData.structureTerritorial.code ===
        "region"
      ) {
        _layer = communesWithoutLayer;
      } else {
        _layer = layers;
      }

      const arrayToMap =
        valueTerritoire.typologieAdminData?.selectedCommunes || [];

      const mappedArray = [...arrayToMap.filter((item) => item.id !== id)];
      if (checked) {
        mappedArray.push({
          ..._layer.find((item) => item.id == id),
          checked: true,
        });
      }

      dispatch(
        formulaireCommandeSlice.actions.set_value_selectedCommunes(mappedArray)
      );
    },
    [layers, valueTerritoire]
  );

  useEffect(() => {
    if (needFitBound) {
      const layer = communes.length ? communes : epci;
      const geojsonLayer = L.geoJSON(layer);
      if (geojsonLayer && geojsonLayer.getBounds().isValid()) {
        map.fitBounds(geojsonLayer.getBounds());
      }
    }
  }, [communes, epci, needFitBound]);

  useEffect(() => {
    setSelectedMunicipalitiesIds(
      (valueTerritoire.typologieAdminData?.selectedCommunes || []).map(
        (commune) => {
          return commune.id;
        }
      )
    );
    setLayerToShow(layers);
  }, [valueTerritoire, currentPage, layers]);

  return (
    <>
      {_.map(layerToShow, (feature) => {
        return (
          <GeoJson
            key={uniqueId("geojson")}
            feature={feature}
            selectedMunicipalitiesIds={selectedMunicipalitiesIds}
            onChangeEnable={handleChangeEnable}
            layerNeedPopup={layerNeedPopup}
          />
        );
      })}
    </>
  );
}

export default GeoJsonContainer;
