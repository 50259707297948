import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import localforage from "localforage";
import { INDEX_DB_NAME } from "../constants/defaultValues";

localforage.config({
  name: INDEX_DB_NAME,
  driver: localforage.INDEXEDDB,
});

// reducers
import formulaireCommandeReducer from "./slices/formulaireCommande";

export const persistConfigformulaireCommande = {
  key: "formulaireCommande",
  storage: localforage,
};

export const combinedReducer = combineReducers({
  formulaireCommande: persistReducer(
    persistConfigformulaireCommande,
    formulaireCommandeReducer
  ),
});
