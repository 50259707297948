import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FeatureGroup,
  MapContainer,
  Popup,
  TileLayer,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import GeoJsonContainer from "./GeoJsonContainer";
import ZonePerso from "./ZonePerso";
import marker from "leaflet/dist/images/marker-icon.png";
import marker2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import SelectCenterAreaLayer from "./SelectCenterAreaLayer";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import { getCommunesByBBox } from "../../redux/actions/formulaireCommande/communeActions";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

const LayersControls = () => {
  return (
    <>
      <LayersControl.BaseLayer name="Classique">
        <TileLayer
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer checked name="Satellite">
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Lumière">
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Nuit">
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.carto.com">Carto</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Cadastre (superposé)">
        <TileLayer
          attribution='Map data: &copy; <a href="https://www.ign.fr">IGN-F/Geoportail</a>'
          url="https://wxs.ign.fr/parcellaire/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&STYLE=PCI vecteur&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
        />
      </LayersControl.BaseLayer>
    </>
  );
};

const defaultZoomLevel = 11;

function TunnelCommandMapContainer() {
  // states
  const [popup, setPopup] = useState(null);
  const [layers, setLayers] = useState([]);
  const [showCommunesEpci, setShowCommunesEpci] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [first, setFirst] = useState(false);
  const [moved, setMoved] = useState(null);
  const [needFitBound, setNeedFitBound] = useState(true);
  const {
    communes,
    epci,
    valueTerritoire: {
      typologieAdminData: { structureTerritorial, territoireCiblage },
    },
  } = useSelector((state) => state.formulaireCommande);

  // refs
  const map = useRef(null);

  const dispatch = useDispatch();

  const handleLayerNeedsPopup = useCallback((e, children) => {
    setPopup({ postion: e.latlng, children });
    setTimeout(() => {
      const els = document.getElementsByClassName("leaflet-popup-close-button");
      for (var i = 0; i < els.length; i++) {
        els[i].addEventListener("click", () => {
          setPopup(null);
        });
      }
    }, 500);
  }, []);

  const getCommunes = async () => {
    if (structureTerritorial.code === "region") {
      const myMap = map.current;
      setNeedFitBound(false);
      if (zoomLevel >= defaultZoomLevel) {
        // get bounds
        const bounds = myMap.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        document.body.className = "waiting";
        await dispatch(
          getCommunesByBBox({
            xmax: northEast.lng,
            ymax: northEast.lat,
            xmin: southWest.lng,
            ymin: southWest.lat,
          })
        );
        document.body.className = "";
        setShowCommunesEpci(true);
      } else {
        setShowCommunesEpci(false);
        dispatch(formulaireCommandeSlice.actions.set_communes([]));
      }
    }
  };

  // debounce effect
  useEffect(() => {
    const fnDebounce = setTimeout(() => {
      if (first) {
        getCommunes();
      }
      setFirst(true);
    }, 1000);
    return () => clearTimeout(fnDebounce);
  }, [zoomLevel, moved]);

  // map event
  useEffect(() => {
    if (structureTerritorial.code === "region") {
      setTimeout(() => {
        const myMap = map.current;
        myMap.on("zoom", (e) => {
          // we call this to run the debounce effect
          setZoomLevel(e.target.getZoom());
        });
        myMap.on("move", (e) => {
          // we call this to run the debounce effect
          setMoved(e.target.getBounds());
        });
        if (myMap.getZoom() >= defaultZoomLevel) {
          setZoomLevel(myMap.getZoom());
        }
      }, 2000);
    } else {
      setNeedFitBound(true);
    }
  }, [structureTerritorial.code]);

  useEffect(() => {
    setNeedFitBound(true);
  }, [territoireCiblage]);

  useEffect(() => {
    const mappedEpci = (epci || []).map((item) => ({
      ...item,
      territoireType: "epci",
    }));
    const mappedCommunes = (communes || []).map((item) => ({
      ...item,
      territoireType: "commune",
    }));
    setLayers(
      structureTerritorial.code === "region" && !showCommunesEpci
        ? mappedEpci
        : showCommunesEpci
        ? [...mappedEpci, ...mappedCommunes]
        : mappedCommunes
    );
  }, [structureTerritorial, epci, communes, showCommunesEpci]);

  return (
    <MapContainer
      center={[46.483229, 2.660239]}
      zoom={5}
      scrollWheelZoom
      className="command_tunnel--map_container"
      ref={map}
    >
      <LayersControl position={"topright"}>
        <LayersControls />
      </LayersControl>
      {layers?.length ? null : <SelectCenterAreaLayer />}
      <FeatureGroup>
        <ZonePerso layerNeedsPopup={handleLayerNeedsPopup} />
      </FeatureGroup>
      {popup && <Popup position={popup.postion}>{popup.children}</Popup>}
      <FeatureGroup>
        <GeoJsonContainer
          layers={layers}
          layerNeedPopup={handleLayerNeedsPopup}
          needFitBound={needFitBound}
        />
      </FeatureGroup>
    </MapContainer>
  );
}

export default TunnelCommandMapContainer;
