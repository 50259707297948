import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../helpers/_api";
import axios from "axios";

export function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const loginEndpoint = process.env.BASE_URL + "/api/v1/login";
    const logOutEndpoint = process.env.BASE_URL + "/api/v1/logout";

    const saveSessionAuthHeader = (sessionAuthHeader) => {
        localStorage.setItem("tzn_token", JSON.stringify(sessionAuthHeader));
        setSessionAuthHeader(sessionAuthHeader);
    };

    const getSessionAuthHeader = () => {
        const token = localStorage.getItem("tzn_token");
        return JSON.parse(token);
    };
    const deleteSessionAuthHeader = () => {
        localStorage.removeItem("tzn_token");
    };

    const [sessionAuthHeader, setSessionAuthHeader] = useState(
        getSessionAuthHeader()
    );

    const logIn = (credential, callback) => {
        return Api.post(loginEndpoint, {user: credential})
            .then((res) => res.data)
            .then(({user, authentication_token}) => {
                setUser({
                    ...user,
                    authentication_token: authentication_token,
                });

                saveSessionAuthHeader({
                    token: authentication_token,
                    email: user.email,
                });
                axios.interceptors.request.use(function (config) {
                    config.headers["X-USER-EMAIL"] = sessionAuthHeader?.email;
                    config.headers["X-USER-TOKEN"] = sessionAuthHeader?.token
                    return config;
                }, function (error) {
                    // Do something with request error
                    return Promise.reject(error);
                });

                callback && callback();
            });
    };

    const logOut = (callback) => {
        return Api.delete(logOutEndpoint)
            .then((res) => res.data)
            .then((token) => {
                localStorage.removeItem("openModal");
                deleteSessionAuthHeader();
                setUser(null);
            })
            .then(() => {
                callback();
            });
    };

    const navigate = useNavigate();

    const checkSession = () => {
        Api.get("/api/v1/me", {
            headers: {
                "X-USER-EMAIL": sessionAuthHeader?.email,
                "X-USER-TOKEN": sessionAuthHeader?.token,
            },
        }).then((res) => {
            if (res.status !== 200) {
                logOut(() => {
                    navigate("/users/sign_in");
                }).then((r) => console.log(r));
            } else {
                setUser(res.data);
            }
        });
    };
    // const checkAdminSession = () => {
    //     axios.get('/api/v1/me', {
    //         headers: {
    //             "X-USER-EMAIL": sessionAuthHeader?.email,
    //             "X-USER-TOKEN": sessionAuthHeader?.token
    //         }
    //     }).then((res) => {
    //         if (res.status !== 200 || res.data.role !== "admin") {
    //             logOut(() => {
    //                 navigate('/users/sign_in')
    //             }).then(r => console.log(r))
    //         } else {
    //             setUser(res.data)
    //             setIsAdmin(res.data.role === "admin")
    //         }
    //     })
    // }

    return {
        user,
        logIn,
        logOut,
        sessionAuthHeader,
        checkSession,
        // checkAdminSession,
        // isAdmin
    };
}
