import React, { useEffect } from "react";
import Alert from "../common/alert/Alert";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import cx from "classnames";
import "./styles.css";
import { updatePage } from "../../redux/actions/formulaireCommande/pageAction";

function ShapesList({ value, onChange, setZoneToEdit }) {
  const handleDeleteClick = (_code) => (e) => {
    e.stopPropagation();
    onChange && onChange(value.filter((item) => item._code != _code));
  };

  const handleClickZonePerson = (_code) => () => {
    onChange &&
      onChange(
        value.map((item) => {
          if (item._code == _code) {
            return {
              ...item,
              checked: item.checked === undefined ? true : !item.checked,
            };
          }
          return item;
        })
      );
  };

  return (
    <>
      <h3 className="font-medium text-sm font-poppins mb-2">Zones perso</h3>
      <div className="list-zone-person-container">
        {value.map(({ _code, name, checked, properties: { color } }) => (
          <div
            onClick={handleClickZonePerson(_code)}
            key={_code}
            className="flex justify-between items-center border rounded-md px-4 py-3 mb-1 bg-white hover:bg-gray-50 cursor-pointer"
          >
            <div className="flex flex-row items-center">
              <div
                style={{ borderColor: color }}
                className="flex items-center justify-center h-5 w-5 border rounded-md mr-4"
              >
                <span
                  style={{ backgroundColor: color }}
                  className={cx("block h-3 w-3 rounded-s", {
                    "!hidden": !checked,
                  })}
                ></span>
              </div>
              <div className="font-medium font-poppins text-xs">{name}</div>
            </div>
            <div className="flex flex-row items-center">
              <span
                className="cursor-pointer mr-4"
                onClick={(e) => {
                  e.stopPropagation();
                  setZoneToEdit && setZoneToEdit(_code);
                }}
              >
                <PencilIcon className="h-6 w-6 text-gray-400 hover:text-gray-600" />
              </span>
              <span
                className="cursor-pointer"
                onClick={handleDeleteClick(_code)}
              >
                <TrashIcon className="h-6 w-6 text-gray-400 hover:text-danger" />
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default function FormulaireZoneEtudeZan() {
  const dispatch = useDispatch();
  const {
    valueTerritoire,
    valueZoneEtudeZan, //{ type, personalizedZones },
    currentPage,
  } = useSelector((state) => state.formulaireCommande);

  const handleChangeZoneEtude = (value) => () => {
    dispatch(
      formulaireCommandeSlice.actions.set_value_zone_etude_zan({
        ...valueZoneEtudeZan,
        type: value,
        personalizedZones:
          value === "tout_territoire"
            ? []
            : valueZoneEtudeZan.personalizedZones,
        selectedPersonalizedZones:
          value === "tout_territoire"
            ? []
            : valueZoneEtudeZan.selectedPersonalizedZones,
      })
    );
  };

  const handleChangeZoneList = (personalizedZones) => {
    dispatch(
      formulaireCommandeSlice.actions.set_value_zone_etude_zan({
        ...valueZoneEtudeZan,
        personalizedZones,
        selectedPersonalizedZones: personalizedZones.filter(
          (item) => item.checked
        ),
      })
    );
  };

  const handleSetZoneToEdit = (_code) => {
    dispatch(formulaireCommandeSlice.actions.set_zone_to_edit(""));
    setTimeout(() => {
      dispatch(formulaireCommandeSlice.actions.set_zone_to_edit(_code));
    }, 50);
  };

  const handleClickModifyFormTerritoire = () => {
    dispatch(
      updatePage({
        current: "form_territoire",
        next: "form_zone_etude_zan",
      })
    );
    const test = (valueTerritoire.typologieAdminData?.selectedCommunes || [])
      .length;
    dispatch(
      formulaireCommandeSlice.actions.set_active_btn_next(test ? true : false)
    );
  };

  useEffect(() => {
    const test =
      (valueZoneEtudeZan.type === "tout_territoire" ||
        (valueZoneEtudeZan.type === "personnaliser" &&
          valueZoneEtudeZan.selectedPersonalizedZones?.length)) &&
      currentPage === "form_zone_etude_zan";

    if (test) {
      dispatch(formulaireCommandeSlice.actions.set_active_btn_next(true));
      dispatch(
        formulaireCommandeSlice.actions.set_next_page("definition_etude")
      );
    } else {
      if (currentPage === "form_zone_etude_zan") {
        dispatch(formulaireCommandeSlice.actions.set_active_btn_next(false));
      }
    }
  }, [valueZoneEtudeZan, currentPage]);

  return (
    <div>
      <div className="flex flex-nowrap justify-between items-center border-b bg-white py-6 px-4">
        <div className="flex justify-between w-full items-center">
          <h2 className="font-paytone-one text-xl">Etape 1/3 : Territoire</h2>
          <div
            onClick={handleClickModifyFormTerritoire}
            className="font-poppins font-medium hover:font-semibold text-danger text-xs cursor-pointer px-8"
          >
            Modifier
          </div>
        </div>
        <ChevronDownIcon className="h-8 w-8" />
      </div>
      <div className="px-4 pt-4">
        <div className="flex flex-nowrap items-center justify-between mb-2">
          <h2 className="font-paytone-one text-xl">
            Etape 2/3 : Zone d’étude ZAN
          </h2>
          <ChevronUpIcon className="h-8 w-8" />
        </div>
        <Alert type="info">
          Il s'agit de la zone spécifique du Territoire sur laquelle vous
          souhaitez avoir un suivi (historique: 2011-2020 et à venir) de
          l'artificialisation des sols.
        </Alert>
        <div className="flex justify-between item-center mt-4">
          <button
            onClick={handleChangeZoneEtude("tout_territoire")}
            className={cx(
              "border rounded-md bg-white hover:bg-gray-50 font-medium text-sm py-4 w-1/2 mr-2",
              { "!border-danger": valueZoneEtudeZan.type === "tout_territoire" }
            )}
          >
            Tout le territoire
          </button>
          <button
            onClick={handleChangeZoneEtude("personnaliser")}
            className={cx(
              "border rounded-md bg-white hover:bg-gray-50 font-medium text-sm py-4 w-1/2 mr-2",
              { "border-danger": valueZoneEtudeZan.type === "personnaliser" }
            )}
          >
            Personnaliser
          </button>
        </div>
        <div
          className={cx("block", {
            "!hidden": valueZoneEtudeZan.type != "personnaliser",
          })}
        >
          <div className="flex justify-between mt-6">
            <h3 className="font-poppins font-medium text-sm">
              Tracez vos zones directement sur la carte
            </h3>
            <span className="cursor-pointer">
              <ExclamationCircleIcon className="h-6 w-6 text-my-blue hover:text-blue-600" />
            </span>
          </div>
          <p className="mt-1 w-3/4 font-poppins font-normal text-xs">
            Vous pouvez préciser des Zones ZAN à étudier dans le périmètre du
            territoire d'étude
          </p>
          <div className="flex justify-between mt-4 items-center">
            <div className="shape-input-container flex flex-row">
              <div id="add_cirle">
                <img src="/images/circle.png" alt="circle" />
              </div>
              <div id="add_rectangle">
                <img src="/images/square.png" alt="square" />
              </div>
              <div id="add_polygone">
                <img src="/images/polygone.png" alt="polygone" />
              </div>
            </div>
            <label className="my-file-input-label" htmlFor="actual-btn">
              <span>Importer un fichier</span>
              <input type="file" id="actual-btn" hidden />
            </label>
          </div>
          <div className="mt-8">
            <ShapesList
              value={valueZoneEtudeZan.personalizedZones}
              onChange={handleChangeZoneList}
              setZoneToEdit={handleSetZoneToEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
