import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import Api from "../helpers/_api";
import {logger} from "@rails/actioncable";

export default function NewPasswordPage() {
    const [passwordsData, setPasswordsData] = useState({
        password: null,
        passwordConfirmation: null
    })
    const navigate = useNavigate()
    const [email, setEmail] = useState(null)
    let {hash_token} = useParams();

    useEffect(() => {
        Api.get(`/api/v1/users/token/${hash_token}`)
            .then((res) => {
                setEmail(res.data.email)
            }).catch(() => navigate('/forgotten-password'))
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault()

        Api.patch('/api/v1/users/update-password', {
            token: hash_token,
            password: passwordsData.password,
            password_confirmation: passwordsData.password_confirmation
        }).then(res => navigate('/login'))
    }
    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Nouveau mot de passe</h2>
                </div>
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form onSubmit={(e) => handleSubmit(e)} className="space-y-6">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <span

                                        className="bg-gray-200 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    >{email}</span>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Mot de passe
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="new-password"
                                        required
                                        onChange={(e) => {
                                            setPasswordsData((prevState) => ({
                                                ...prevState,
                                                password: e.target.value
                                            }))
                                        }}

                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Confirmation mot de passe
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password_confirmation"
                                        type="password"
                                        name="password_confirmation"
                                        autoComplete="new-password"
                                        required
                                        onChange={(e) => {
                                            setPasswordsData((prevState) => ({
                                                ...prevState,
                                                passwordConfirmation: e.target.value
                                            }))
                                        }}

                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-red-500 bg-red-500 text-red-50 rounded-md shadow-sm text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Réinitialiser mon mot de passe
                                </button>
                            </div>
                            <div className="sm:col-span-2">
                                <button
                                    type="button"
                                    onClick={() => navigate('/login')}
                                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-red-500 rounded-md shadow-sm text-base font-medium text-white hover:bg-red-50 text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Me connecter
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}