import { GeoJSON, Tooltip } from "react-leaflet";
import { spacingThousands } from "../../helpers/_format_number";
import SwitchInput from "../common/switch-input/SwitchInput";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";

const PopupGeoJsonCommune = ({
  properties: {
    population,
    part_logement_collectif,
    surface_commune_meter_square,
    name,
  },
  onChangeEnable,
  id,
}) => {
  const [enabled, setEnabled] = useState(false);

  const {
    valueTerritoire: { typologieAdminData },
  } = useSelector((state) => state.formulaireCommande);

  const handleChangeSwitch = useCallback(
    (value) => {
      onChangeEnable && onChangeEnable(value, id);
    },
    [id]
  );

  useEffect(() => {
    setEnabled(
      typologieAdminData.selectedCommunes.find((item) => item.id == id)
    );
  }, [typologieAdminData.selectedCommunes, id]);

  return (
    <div className="text-center divide-y-2">
      <div className="text-left">
        <h2 className="text-gray-900 text-base font-bold">{name}</h2>
        <p className="text-xl"></p>
      </div>
      <div className="py-2">
        <ul className="text-left">
          <li className="text-sm font-bold text-black">
            {spacingThousands(
              Math.floor((surface_commune_meter_square || 0) / 1000000)
            )}{" "}
            km²
          </li>
          <li className="text-sm font-bold text-black">
            {spacingThousands(population || 0)} habitants
          </li>
          <li className="text-sm font-bold text-black">
            {part_logement_collectif && part_logement_collectif.toFixed(1)}%
            log. collectifs
          </li>
        </ul>
        <div className="pt-4">
          <SwitchInput
            label="Selection"
            enabled={enabled}
            setEnabled={handleChangeSwitch}
          />
        </div>
      </div>
    </div>
  );
};

function GeoJSONComp({
  feature,
  selectedMunicipalitiesIds,
  onChangeEnable,
  layerNeedPopup,
}) {
  const geoJsonLayer = useRef(null);
  const {
    valueTerritoire: { typologieAdminData },
    currentPage,
    communesWithoutLayer,
  } = useSelector((state) => state.formulaireCommande);

  const properties = useMemo(() => {
    if (
      typologieAdminData.structureTerritorial.code === "region" &&
      feature.territoireType === "commune"
    ) {
      return (
        communesWithoutLayer.find((item) => item.id == feature.id)
          ?.properties || {}
      );
    }
    return feature.properties;
  }, [feature, typologieAdminData, communesWithoutLayer]);

  // show popup effect
  useEffect(() => {
    if (geoJsonLayer.current) {
      const layer = geoJsonLayer.current;
      layer.on("click", (e) => {
        if (
          currentPage === "form_territoire" &&
          feature.territoireType === "commune"
        ) {
          layerNeedPopup &&
            layerNeedPopup(
              e,
              <PopupGeoJsonCommune
                properties={properties}
                onChangeEnable={onChangeEnable}
                id={feature.id}
              />
            );
        }
      });
    }
  }, []);

  return (
    <GeoJSON
      data={feature}
      ref={geoJsonLayer}
      pathOptions={
        selectedMunicipalitiesIds.includes(properties.national_code) ||
        selectedMunicipalitiesIds.includes(feature.id)
          ? {
              fillColor: "#ff375f",
              weight: 3,
              opacity: "1",
              color: "#ff375f",
              dashArray: "2",
              fillOpacity: 0.2,
            }
          : {
              fillColor:
                currentPage === "form_zone_etude_zan" ? "#0488d0" : "#0050E3",
              weight: feature.territoireType === "epci" ? 5 : 3,
              opacity: "1",
              color: "#0050E3",
              dashArray: "2",
              fillOpacity:
                feature.territoireType === "epci" ||
                currentPage === "form_zone_etude_zan" ||
                currentPage === "definition_etude"
                  ? 0.1
                  : 0.3,
              opacity:
                feature.territoireType === "epci" ||
                currentPage === "form_zone_etude_zan" ||
                currentPage === "definition_etude"
                  ? 0.2
                  : 0.3,
            }
      }
    >
      {feature.territoireType === "epci" && (
        <Tooltip sticky>
          Zoomez sur les EPCI composant la région pour voir apparaître les
          communes sur la carte.
        </Tooltip>
      )}
    </GeoJSON>
  );
}

export default GeoJSONComp;
