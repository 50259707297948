import React from "react";
import { Tooltip } from "flowbite-react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import "./styles.css";

export default function MoreInfo({ content }) {
  return (
    <Tooltip content={content} animation="duration-1000">
      <span>
        <ExclamationCircleIcon className="h-6 w-6 text-my-blue cursor-pointer" />
      </span>
    </Tooltip>
  );
}
