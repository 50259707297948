import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { store } from "./redux";
import { Provider as ReduxProvider } from "react-redux";

// pages
import Home from "./pages/Home";
// import { RequireAuth } from "./components/auth/RequireAuth";
import { LoginPage } from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Honeybadger } from "@honeybadger-io/react";

function App() {
  // useEffect(() => {
  //   LogRocket.init("mister-e/territoireszan");
  //   setupLogRocketReact(LogRocket);
  //   LogRocket.getSessionURL((sessionURL) => {
  //     Honeybadger.beforeNotify((notice) => {
  //       notice.context.sessionURL = sessionURL;
  //     });
  //   });
  // }, []);

  return (
    <ReduxProvider store={store}>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
        <Route
          path="/users/password/edit/:hash_token"
          element={<NewPasswordPage />}
        />
      </Routes>
    </ReduxProvider>
  );
}

export default App;
