import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  formulaireCommandeActions,
  formulaireCommandeSlice,
} from "../../slices/formulaireCommande";
import Axios from "../../../helpers/axios";

export const getCommunesByBBox = createAsyncThunk(
  "formulaireCommande",
  async ({ xmin, ymin, xmax, ymax }, { dispatch, getState }) => {
    const nationalCode = getState().formulaireCommande.nationalCode;
    try {
      const {
        data: { features },
      } = await Axios.get(
        `/v4/territoires_zan/region/communes_by_bbox?xmin=${xmin}&ymin=${ymin}&xmax=${xmax}&ymax=${ymax}&filter_region_national_code=${nationalCode}`
      );
      dispatch(
        formulaireCommandeSlice.actions.set_communes(
          features.filter((item) => item.id != "75056")
        )
      );
    } catch (e) {
      console.log(e);
    }
  }
);

export const getCommunes = createAsyncThunk(
  "formulaireCommande/getCommunes",
  async ({ code, type = "epci" }, { dispatch, getState }) => {
    const { data } = await Axios.get(
      `/v4/territoires_zan/${type}/communes?${
        ["epci"].includes(type) ? "national_code" : "scot_id"
      }=${code}`
    );
    const currentValuesTerritoire =
      getState().formulaireCommande.valueTerritoire;
    dispatch(formulaireCommandeSlice.actions.set_communes_without_layer([]));
    dispatch(
      formulaireCommandeSlice.actions.set_value_territoire({
        ...currentValuesTerritoire,
        typologieAdminData: {
          ...currentValuesTerritoire.typologieAdminData,
          selectedCommunes: [],
        },
      })
    );
    if (data?.features) {
      dispatch(formulaireCommandeSlice.actions.set_communes(data?.features));
    } else {
      dispatch(formulaireCommandeSlice.actions.set_communes([]));
    }
  }
);

export const getCommunesByIsochrone = createAsyncThunk(
  "formulaireCommande/getCommunesByIsochrone",
  (_, { dispatch, getState }) => {
    const {
      valueIsochroneFilter: {
        isoChroneAddress,
        transportDuration,
        transportMode,
      },
    } = getState().formulaireCommande;

    dispatch(formulaireCommandeActions.set_selected_communes([]));
    dispatch(formulaireCommandeActions.set_loading({ communes: true }));
    Axios.get(
      `/v4/eval_parcel/ciblage/zones_by_isochrone?transport_mode=${
        transportMode.value
      }&transport_time_max=${transportDuration * 60}&lon=${
        isoChroneAddress.lon
      }&lat=${isoChroneAddress.lat}`
    )
      .then(({ data: { features } }) => {
        const dataToSave = (features || []).map((item) => ({
          ...item,
          properties: {
            ...item.properties,
            population: item.properties.population_2018,
          },
        }));
        dispatch(formulaireCommandeActions.set_communes(dataToSave));
      })
      .finally(() => {
        dispatch(formulaireCommandeActions.set_loading({ communes: false }));
      });
  }
);
