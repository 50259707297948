import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { XCircleIcon } from "@heroicons/react/solid";
import cx from "classnames";
import levenshtein from "fast-levenshtein";
import "./styles.css";

const CustomAutocomplete = forwardRef(
  (
    {
      placeholder = "",
      options,
      value,
      keyValue,
      keyLabel,
      onChange,
      onChangeInputValue,
      serverSide = false,
      name,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [valueText, setValueText] = useState("");
    const [filteredOptions, setFilterOptions] = useState(options);
    const [first, setFirst] = useState(false);

    useImperativeHandle(ref, () => ({
      setEmptyValueText() {
        setValueText("");
      },
    }));

    const handleChange = (e) => {
      setValueText(e.target.value);
      const keyword = e.target.value.trim().toLowerCase();
      if (keyword.length) {
        setOpen(true);
      } else {
        setOpen(false);
      }
      if (!serverSide) {
        setFilterOptions(
          (options || [])
            .filter((item) => {
              const testIndexOf =
                (item[keyLabel] || "").trim().toLowerCase().indexOf(keyword) >=
                0;
              const levenshteinTest =
                levenshtein.get(
                  (item[keyLabel] || "").trim().toLowerCase(),
                  keyword
                ) <= 1;
              return testIndexOf || levenshteinTest;
            })
            .sort((a, b) => {
              levenshtein.get(
                (b[keyLabel] || "").trim().toLowerCase(),
                keyword
              ) -
                levenshtein.get(
                  (a[keyLabel] || "").trim().toLowerCase(),
                  keyword
                );
            })
        );
      }
    };

    const handleClick = () => {
      setTimeout(() => {
        setOpen(!open);
      }, 100);
    };

    const handleClickRemove = () => {
      setValueText("");
      setFilterOptions(options);
      onChange && onChange(null, name);
      setTimeout(() => {
        setOpen(false);
      }, 101);
    };

    useEffect(() => {
      const closeSelect = () => {
        setOpen(false);
      };
      window.addEventListener("click", closeSelect);
      return () => {
        window.removeEventListener("click", closeSelect);
      };
    }, []);

    useEffect(() => {
      setValueText(value ? value[keyLabel] : "");
    }, [value]);

    useEffect(() => {
      const fnDebounce = setTimeout(() => {
        if (first) {
          onChangeInputValue && onChangeInputValue(valueText);
        }
        setFirst(true);
      }, 1000);
      return () => clearTimeout(fnDebounce);
    }, [valueText]);

    return (
      <div
        className="relative custom-autocomplete-container border rounded-md bg-white cursor-pointer"
        onClick={handleClick}
      >
        <div className="flex items-center justify-between h-full px-4">
          <input
            type="text"
            value={valueText}
            placeholder={placeholder}
            className="w-full border-none bg-white p-0 font-medium font-poppins text-xs"
            onChange={handleChange}
          />
          {valueText.length ? (
            <XCircleIcon
              onClick={handleClickRemove}
              className="h-5 w-5 text-gray-400"
            />
          ) : null}
        </div>
        {!!valueText.length && (
          <div
            className={cx(
              { hidden: !open },
              { block: open },
              "custom-autocomplete-item-container absolute z-50 top-[49px] left-0 border rounded-md overflow-hidden bg-white w-full"
            )}
          >
            {(serverSide ? options : filteredOptions).map((item) => (
              <div
                onClick={() => {
                  onChange && onChange(item, name);
                  setValueText(item[keyLabel]);
                }}
                className="font-normal w-full text-xs font-poppins hover:bg-gray-100 p-3 border-b"
                key={item[keyValue]}
              >
                {item[keyLabel]}
              </div>
            ))}

            {(serverSide ? options : filteredOptions).length === 0 && (
              <div className="font-normal italic text-gray-400 w-full text-xs font-poppin p-3 border-b">
                Aucuns résultats
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default CustomAutocomplete;
