import React, { useRef } from "react";
import classNames from "classnames";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { LightBulbIcon } from "@heroicons/react/outline";

export default function ({ label, img, onClick, enabled, selected }) {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={classNames(
          { "opacity-30 cursor-not-allowed": !enabled },
          "flex justify-between items-center p-6 border rounded-md mb-2 bg-white hover:bg-gray-100 cursor-pointer",
          { "bg-gray-100": selected }
        )}
        onClick={() => {
          if (enabled) {
            onClick();
          } else {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div
          style={{ width: 110 }}
          className="font-poppins text-sm font-medium"
        >
          {label}
        </div>
        <div>
          <img src={img} className="h-10" alt="icon" />
        </div>
      </div>
      <ModalContact open={isOpen} setOpen={setIsOpen} />
    </>
  );
}

function ModalContact({ open, setOpen }) {
  const cancelButtonRef = useRef(null);

  function handleContact() {
    location.href =
      "mailto:contact@territoireszan.com?&subject=Demande d'information sur la  recherche avancée";
    setOpen(false);
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <LightBulbIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      La fonctionnalité vous interesse ?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        contactez nous pour savoir comment accéder à cette
                        fonctionalité.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-danger px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={() => {
                      handleContact();
                    }}
                  >
                    Nous contacter
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
