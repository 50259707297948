import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import CustomSelect from "../common/custom-select/CustomSelect";
import CustomAutocomplete from "../common/custom-autocomplete/CustomAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import { getTerritoiresDuCiblage } from "../../redux/actions/formulaireCommande/territoireActions";
import { getCommunes } from "../../redux/actions/formulaireCommande/communeActions";
import { getEpcis } from "../../redux/actions/formulaireCommande/epciActions";
import "./styles.css";

export const structuresterritoriales = [
  {
    code: "epci",
    label: "EPCI",
  },
  {
    code: "scot",
    label: "SCOT",
  },
  {
    code: "region",
    label: "Région",
  },
];

export const CommunesSelect = ({ options, onChange, value }) => {
  const [mappedOptions, setMappedOption] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  const handleClickCommune = (id) => {
    const mappedArray = mappedOptions.map((item) => {
      if (item.id == id) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setMappedOption(mappedArray);
    onChange && onChange(mappedArray.filter((item) => item.checked));
  };

  const handleClickAll = () => {
    const mappedArray = options.map((item) => ({
      ...item,
      checked: selectAll,
    }));
    onChange && onChange(mappedArray.filter((item) => item.checked));
    setMappedOption(mappedArray);
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const mappedArray = options.map((item) => {
      if (
        value &&
        value.length &&
        value.map((item) => item.id).includes(item.id)
      ) {
        return value.find((el) => el.id === item.id);
      }
      return { ...item, checked: false };
    });
    setMappedOption(mappedArray);
    setSelectAll(value?.length !== options.length);
  }, [value, options]);

  return (
    <div className="h-full flex flex-col">
      {!!options.length && (
        <div className="flex justify-between items-center font-poppins font-medium">
          <span className="text-sm">
            {mappedOptions.filter((item) => item.checked).length} communes
            sélectionnées
          </span>
          <span
            onClick={handleClickAll}
            className="text-xs text-red-500 cursor-pointer hover:font-semibold"
          >
            {selectAll ? "Tout sélectionner" : "Tout désélectionner"}
          </span>
        </div>
      )}
      <div className="mt-2  grow h-0">
        <div className="max-h-full overflow-y-auto pr-6">
          {(mappedOptions || []).map((item) => (
            <div
              key={item.id}
              onClick={() => handleClickCommune(item.id)}
              className="commune_item flex flex-row items-center cursor-pointer border rounded-md bg-white mb-1 pl-3 hover:bg-gray-50"
            >
              <div className="commune_item_checkox mr-4">
                <span className={`${item.checked ? "block" : "hidden"}`}></span>
              </div>
              <div className="font-poppins font-medium text-xs">
                {item.properties.name}&nbsp;({item.id})
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function FormulaireTypologieAdmin({ onChange, value }) {
  const { territoiresDuCiblage, communes, communesWithoutLayer } = useSelector(
    (state) => state.formulaireCommande
  );

  const communesOptions = useMemo(
    () => (communesWithoutLayer.length ? communesWithoutLayer : communes),
    [communes, communesWithoutLayer]
  );

  const autocompleteRef = useRef();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    structureTerritorial: structuresterritoriales[0],
    territoireCiblage: null,
    selectedCommunes: [],
  });

  const [loading, setLoading] = useState(false);

  const handleOnChange = (key) => async (value) => {
    const currentValue = { ...formData, [key]: value };
    onChange && onChange(currentValue);
    setFormData(currentValue);

    // get communes
    if (key === "territoireCiblage") {
      dispatch(formulaireCommandeSlice.actions.set_communes([]));
      dispatch(formulaireCommandeSlice.actions.set_communes_without_layer([]));
      dispatch(formulaireCommandeSlice.actions.set_epci([]));
      setLoading(true);
      if (formData.structureTerritorial.code === "region") {
        await dispatch(getEpcis(value?.code || ""));
      } else {
        await dispatch(
          getCommunes({
            code: value?.code || "",
            type: formData.structureTerritorial.code,
          })
        );
      }

      setLoading(false);
      setFormData((state) => ({ ...state, selectedCommunes: [] }));
    }

    if (key === "structureTerritorial") {
      dispatch(formulaireCommandeSlice.actions.set_territoire_du_ciblage([]));
      dispatch(formulaireCommandeSlice.actions.set_communes([]));
      dispatch(formulaireCommandeSlice.actions.set_communes_without_layer([]));
      if (autocompleteRef.current) {
        autocompleteRef.current.setEmptyValueText();
      }
    }
  };

  const handleChangeInputValueAutocomplete = useCallback(
    (keyword) => {
      if (formData.structureTerritorial) {
        dispatch(
          getTerritoiresDuCiblage({
            keyword,
            type: formData.structureTerritorial.code,
          })
        );
      }
    },
    [formData.structureTerritorial]
  );

  useEffect(() => {
    setFormData({
      structureTerritorial: value?.structureTerritorial || null,
      territoireCiblage: value?.territoireCiblage || null,
      selectedCommunes: value?.selectedCommunes || null,
    });
  }, [value]);

  return (
    <div className="flex flex-col mt-4 h-full">
      <div>
        <CustomSelect
          placeholder="..."
          keyLabel="label"
          keyValue="code"
          onChange={handleOnChange("structureTerritorial")}
          options={structuresterritoriales}
          value={formData.structureTerritorial}
        />
        <div className="mt-2">
          <label className="font-medium font-poppins text-xs ml-2 mb-2">
            Territoire
          </label>
          <CustomAutocomplete
            ref={autocompleteRef}
            placeholder={`indiquez un ${formData.structureTerritorial.label}`}
            keyLabel="name"
            keyValue="code"
            onChange={handleOnChange("territoireCiblage")}
            onChangeInputValue={handleChangeInputValueAutocomplete}
            options={territoiresDuCiblage}
            value={formData.territoireCiblage}
            serverSide
          />
        </div>
      </div>
      <div
        className={`grow flex flex-col ${
          formData.territoireCiblage ? "block mt-4" : "hidden"
        }`}
      >
        {loading && (
          <div className="font-poppins font-medium text-sm ">
            Chargement en cours...
          </div>
        )}
        <div className={`grow ${loading ? "hidden" : "block"}`}>
          <CommunesSelect
            options={communesOptions}
            onChange={handleOnChange("selectedCommunes")}
            value={formData.selectedCommunes}
          />
        </div>
      </div>
    </div>
  );
}
