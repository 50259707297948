import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { spacingThousands } from "../../helpers/_format_number";
import cx from "classnames";
import "./styles.css";
import classNames from "classnames";

export const Recap = () => {
  const { valueTerritoire, currentPage } = useSelector(
    (state) => state.formulaireCommande
  );

  const {
    population,
    selectedCommunesTotal,
    surface,
    logCollectif,
    selectedCommunes,
  } = useMemo(() => {
    return {
      population: (
        valueTerritoire.typologieAdminData?.selectedCommunes || []
      ).reduce(
        (prev, current) => prev + (current?.properties?.population || 0),
        0
      ),
      selectedCommunesTotal: (
        valueTerritoire.typologieAdminData?.selectedCommunes || []
      ).length,
      surface: (
        valueTerritoire.typologieAdminData?.selectedCommunes || []
      ).reduce(
        (prev, current) =>
          prev + (current?.properties?.surface_commune_meter_square || 0),
        0
      ),
      selectedCommunes:
        valueTerritoire.typologieAdminData?.selectedCommunes || [],
      logCollectif:
        (valueTerritoire.typologieAdminData?.selectedCommunes || []).reduce(
          (prev, current) =>
            prev + (current?.properties?.part_logement_collectif || 0),
          0
        ) / (valueTerritoire.typologieAdminData?.selectedCommunes || []).length,
    };
  }, [valueTerritoire]);

  return (
    <div
      className={cx("footer-form-commande w-full border-t overflow-hidden", {
        hidden: currentPage === "segmentation",
      })}
    >
      <div
        className={cx("footer-form-commande-data-container", {
          "!hidden": selectedCommunes.length === 0,
        })}
      >
        <div>
          <div>
            <span className="font-semibold text-2xl">
              {spacingThousands(Math.floor(population))}
            </span>
            <span className="font-semibold text-xs -mt-1">habitants</span>
          </div>
        </div>
        <div>
          <div>
            <span className="font-semibold text-2xl">
              {Math.floor(isNaN(logCollectif) ? 0 : logCollectif)}
              <span className="ml-1 text-sm">%</span>
            </span>
            <span className="font-semibold text-xs -mt-1">log. collectifs</span>
          </div>
        </div>
        <div>
          <div>
            <span className="font-semibold text-2xl">
              {selectedCommunesTotal}
            </span>
            <span className="font-semibold text-xs -mt-1">communes</span>
          </div>
        </div>
        <div>
          <div>
            <span className="font-semibold text-2xl">
              {spacingThousands(Math.floor(surface / 1000000))}&nbsp;km²
            </span>
            <span className="font-semibold text-xs ">de surface totale</span>
          </div>
        </div>
      </div>
      <div
        className={cx(
          { "!hidden": selectedCommunes.length },
          "footer-form-commande-placehoder"
        )}
      >
        <div>
          <span></span>
          <span></span>
        </div>
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

const setWidthBtnInFooter = () => {
  const formulaireContainer = document.getElementById("formulaire-container");
  const footer = document.getElementById("footer-form-commande");
  if (formulaireContainer && footer) {
    footer.style.width = `${formulaireContainer.clientWidth}px`;
    formulaireContainer.style.paddingBottom = `${footer.clientHeight + 8}px`;
  }
};

export default function FooterFormulaireCommande({ onClickNext }) {
  const { valueTerritoire, activeBtnNext, currentPage } = useSelector(
    (state) => state.formulaireCommande
  );

  useEffect(() => {
    setTimeout(() => {
      setWidthBtnInFooter();
    }, 0);
    setWidthBtnInFooter();
    window.addEventListener("resize", setWidthBtnInFooter);
    return () => {
      window.removeEventListener("resize", setWidthBtnInFooter);
    };
  }, [currentPage, valueTerritoire.typologieAdminData.selectedCommunes]);

  return (
    <div
      id="footer-form-commande"
      className={classNames("hidden fixed bottom-0 right-0", {
        "!block": valueTerritoire.typologieAdminData,
      })}
    >
      <Recap />
      <div
        className={cx("block px-6 py-4 bg-white", {
          "!hidden":
            currentPage === "definition_etude" ||
            currentPage === "segmentation",
        })}
      >
        <button
          disabled={!activeBtnNext}
          onClick={onClickNext}
          className={cx(
            "bg-danger font-poppins rounded-md font-semibold text-xs text-white w-full h-10",
            { "!bg-gray-300 !cursor-not-allowed": !activeBtnNext }
          )}
        >
          Suivant
        </button>
      </div>
      <div
        className={cx("hidden px-6 py-6 bg-white items-center", {
          "!flex": currentPage === "definition_etude",
        })}
      >
        <button
          style={{ height: 45 }}
          disabled={!activeBtnNext}
          onClick={onClickNext}
          className={cx(
            "bg-danger font-poppins rounded-md font-semibold text-xs text-white w-full h-10",
            { "!bg-gray-300 !cursor-not-allowed": !activeBtnNext }
          )}
        >
          Demande de devis
        </button>
      </div>
      <div
        className={cx("hidden px-6 py-6 bg-white items-center", {
          "!flex": currentPage === "segmentation",
        })}
      >
        <button
          onClick={onClickNext}
          style={{ height: 45 }}
          className="bg-gray-900 hover:bg-gray-700 font-poppins font-medium text-xs text-white w-full rounded-md"
        >
          Valider ma sélection
        </button>
      </div>
    </div>
  );
}
