import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import FooterFormulaireCommande from "../footer-formulaire-commande/FooterFormlaierCommande";
import FormulaireTerritoire from "../formulaire-territoire/FormulaireTerritoire";
import FormulaireZoneEtudeZan from "../formulaire-zone-etude-zan/FormulaireZoneEtudeZan";
import FormulaireDefinitionEtude from "../formulaire-definition-etude/FormulaireDefinitionEtude";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";
import FormulaireSegmentation from "../formulaire-segmentation/FormulaireSegmentation";
import dateDiffInDays from "../../helpers/dateDiffInDays";
import { updatePage } from "../../redux/actions/formulaireCommande/pageAction";
import { INDEX_DB_NAME } from "../../constants/defaultValues";
import moment from "moment";

const orderedPages = [
  "form_territoire",
  "form_zone_etude_zan",
  "definition_etude",
  // "segmentation",
  "recap",
];

const FormulaireCommandeTunnel = () => {
  const { nextPage, currentPage, createdAt } = useSelector(
    (state) => state.formulaireCommande
  );
  const dispatch = useDispatch();

  const handleClickNext = useCallback(() => {
    dispatch(
      updatePage({
        current: nextPage,
        next: orderedPages[orderedPages.indexOf(nextPage) + 1] || "",
      })
    );
  }, [nextPage]);

  useEffect(() => {
    if (!createdAt) {
      dispatch(formulaireCommandeSlice.actions.set_created_at(new Date()));
    } else {
      const duration = moment
        .duration(moment().diff(moment(new Date(createdAt))))
        .asDays();
      if (duration >= 2) {
        const req = indexedDB.deleteDatabase(INDEX_DB_NAME);
        req.onsuccess = () => {
          window.location.assign("/");
        };
      }
    }
  }, [createdAt]);

  return (
    <div className="h-full flex flex-col justify-between overflow-y-auto">
      <div className="h-full">
        <div
          className={cx("hidden ", {
            "!block h-full": currentPage === "form_territoire",
          })}
        >
          <FormulaireTerritoire />
        </div>
        <div
          className={cx("hidden", {
            "!block h-full": currentPage === "form_zone_etude_zan",
          })}
        >
          <FormulaireZoneEtudeZan />
        </div>
        <div
          className={cx("hidden", {
            "!block h-full": currentPage === "definition_etude",
          })}
        >
          <FormulaireDefinitionEtude />
        </div>
        <div
          className={cx("hidden", {
            "!block h-full": currentPage === "segmentation",
          })}
        >
          <FormulaireSegmentation />
        </div>
      </div>
      <FooterFormulaireCommande onClickNext={handleClickNext} />
    </div>
  );
};

export default FormulaireCommandeTunnel;
