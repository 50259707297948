import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import useDebounce from "../../../hooks/useDebounce";

export default function SliderInput({
  disabled,
  step = 1,
  min = 0,
  max,
  value,
  onChange,
  name,
}) {
  const [valueSlider, setValueSlider] = useState(null);

  const handleChange = (_value) => {
    setValueSlider(_value);
  };

  useDebounce(
    () => {
      if (value !== valueSlider) {
        onChange && onChange(valueSlider, name);
      }
    },
    [valueSlider],
    500
  );

  useEffect(() => {
    setValueSlider(value);
  }, [value]);

  const getValue = () => {
    return valueSlider !== null || valueSlider !== undefined
      ? valueSlider
      : max;
  };

  return (
    <div className="w-full flex items-center">
      <Slider
        onChange={handleChange}
        value={getValue()}
        min={min}
        max={max}
        step={step}
        className="w-full"
        allowCross={false}
        disabled={disabled}
        trackStyle={{
          backgroundColor: !disabled ? "#1BA3EF" : "#CCC",
          borderColor: !disabled ? "#1BA3EF" : "#CCCC",
        }}
        handleStyle={{
          backgroundColor: !disabled ? "#1BA3EF" : "#CCC",
          borderColor: !disabled ? "#1BA3EF" : "#CCC",
          width: "1.5rem",
          height: "1.5rem",
          marginTop: "-10px",
        }}
        railStyle={{ backgroundColor: "#EBEBEB" }}
      />
      <span className="ml-4 text-sm">{getValue()}</span>
    </div>
  );
}
