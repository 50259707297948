import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "../src/App";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

import "leaflet/dist/leaflet.css";
import AuthProvider from "../src/providers/AuthProvider";

document.addEventListener("DOMContentLoaded", () => {
  const rootEl = document.getElementById("root");
  const root = createRoot(rootEl);

  const config = {
    apiKey: "hbp_IGgeBrk2s9TtGACkffaSKnKPcn2UBL2Dfkt3",
    environment: "production",
    revision: "git SHA/project version",
  };

  let honeybadger = {};
  if (process.env.NODE_ENV === "production") {
    honeybadger = Honeybadger.configure(config);
  }

  root.render(
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </HoneybadgerErrorBoundary>
  );
});
