import { useEffect, useState } from "react";

export default function useDebounce(callback, deps, delay = 500) {
  const [first, setFirst] = useState(false);

  useEffect(() => {
    const fn = setTimeout(() => {
      if (first) {
        callback && callback();
      }
      setFirst(true);
    }, delay);
    return () => clearTimeout(fn);
  }, deps);
  return null;
}
