import React, { useState } from "react";
import cx from "classnames";
import { segmentation } from "../../redux/slices/formulaireCommande";
import { segmentationMos } from "../formulaire-segmentation/FormulaireSegmentation";

export default function SelectSegmentationMos({ value, onChange }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((state) => !state);
  };

  const handleClickValues = (id) => () => {
    onChange && onChange(segmentation.find((item) => item.id === id));
  };

  return (
    <div className="relative">
      <div
        style={{ height: 45 }}
        className="bg-white flex justify-between items-center w-full border rounded-md px-4"
        onClick={handleClick}
      >
        <div className="font-poppins font-medium text-xs">
          {value?.name || ""}
        </div>
        <div className="font-poppins text-danger hover:text-red-600 font-semibold  text-xs cursor-pointer">
          {open ? "Fermer" : "Modifier"}
        </div>
      </div>
      <div
        style={{ top: 45 + 4 }}
        className={cx(
          "absolute z-50 hidden bg-white w-full border rounded-md shadow-md",
          {
            "!block": open,
          }
        )}
      >
        {segmentation.map(({ id, name }) => (
          <div
            className={cx(
              "font-poppins text-xs border-b p-4 cursor-pointer hover:bg-gray-100 last:border-none",
              { "bg-gray-100": id === value?.id }
            )}
            key={id}
            onClick={handleClickValues(id)}
          >
            {id === 1 ? (
              <>
                <span className="font-poppins font-medium text-sm block mb-2">
                  {name}
                </span>
                {segmentationMos.map(({ code, label }) => (
                  <div key={code} className="flex mb-1">
                    <img src={`images/segmentation/${code}.png`} alt={code} />
                    <span className="ml-2">{label}</span>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="font-poppins font-medium text-sm mb-2">
                  {name}
                </div>
                <p className="font-poppins text-xs font-normal text-gray-500">
                  Selectionnez cette option pour une segmentation sur mesure.
                  Nous vous contacterons pour définir ensemble votre besoin.
                </p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
