import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { formulaireCommandeSlice } from "../../redux/slices/formulaireCommande";

const legendItems = [
  {
    id: 1,
    coord: [46.483229, 2.660239],
    label: "France métropolitaine",
    zoom: 6,
  },
  { id: 2, coord: [16.1735, -61.4053], label: "Guadeloupe", zoom: 11 },
  { id: 3, coord: [3.946, -53.119], label: "Guyane", zoom: 8 },
  { id: 4, coord: [-21.131, 55.5266], label: "La Réunion", zoom: 11 },
  { id: 5, coord: [14.6369, -61.0194], label: "Martinique", zoom: 11 },
  { id: 6, coord: [-12.8288, 45.1591], label: "Mayotte", zoom: 11 },
  { id: 7, coord: [-20.479, 165.145], label: "Nouvelle-Calédonie", zoom: 7 },
  { id: 8, coord: [-18.177, -144.58], label: "Polynésie française", zoom: 6 },
  { id: 9, coord: [17.9225, -62.8701], label: "Saint-Barthélemy", zoom: 13 },
  { id: 10, coord: [18.0859, -63.0619], label: "Saint-Martin", zoom: 13 },
  {
    id: 11,
    coord: [46.95, -56.2685],
    label: "Saint-Pierre-et Miquelon",
    zoom: 11,
  },
];

const SelectCenterAreaLayer = () => {
  const [activeItem, setActiveItem] = useState(null);
  const { centerMap, currentPage } = useSelector(
    (state) => state.formulaireCommande
  );
  const dispatch = useDispatch();

  const handleClickitem = (id, coord, zoom) => () => {
    setActiveItem(id);
    map.flyTo(coord, zoom, { duration: 1.75 });
    dispatch(
      formulaireCommandeSlice.actions.set_center_map({
        id,
        coord,
        zoom,
      })
    );
  };

  useEffect(() => {
    if (centerMap && currentPage === "form_territoire") {
      setActiveItem(centerMap.id);
      if (centerMap.coord && centerMap.zoom) {
        map.flyTo(centerMap.coord, centerMap.zoom, { duration: 1.75 });
      }
    }
  }, [centerMap, currentPage]);

  const map = useMap();
  if (!map) return null;

  return (
    <>
      <div
        className={"absolute bottom-3 left-3 bg-white p-3 rounded"}
        style={{ zIndex: 400 }}
      >
        {legendItems.map((item, index) => {
          return (
            <div
              key={`legend-item__${index}`}
              className={"block flex-row items-center"}
            >
              <button
                className={classNames(
                  { "text-red-400 font-bold": activeItem === item.id },
                  "p-1 text-xs"
                )}
                onClick={handleClickitem(item.id, item.coord, item.zoom)}
              >
                {item.label}
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SelectCenterAreaLayer;
