import { createSlice } from "@reduxjs/toolkit";
import { structuresterritoriales } from "../../components/ formulaire-typologie-admin/FormulaireTypologieAdmin";
import { DateTime } from "luxon";
import {
  TRANSPORTMODE,
  TRANSPORT_DURATION_MAX,
} from "../../constants/defaultValues";

const currentYear = parseInt(
  DateTime.now().toLocaleString({ year: "numeric" })
);

export const transform = (state) => JSON.parse(JSON.stringify(state));

export const imagerieProviders = [
  {
    code: "airbus",
    label: "Airbus Pléiades Neo",
  },
  {
    code: "custom",
    label: "Utilisez vos propres imageries",
  },
];

export const segmentation = [
  {
    id: 1,
    name: "Segmentation / MOS - 5 élements",
  },
  {
    id: 2,
    name: "Segmentation sur mesure",
  },
];

export const typologieAdminDataDefaultValue = {
  structureTerritorial: structuresterritoriales[0],
  territoireCiblage: null,
  isochroneFilter: null,
  selectedCommunes: [],
};

export const valueZoneEtudeDefaultValue = {
  type: null,
  personalizedZones: [],
  selectedPersonalizedZones: [],
};

export const valueDefinitionEtudeDefaultValue = {
  segmentationMos: segmentation[0],
  imagerieProvider: imagerieProviders[0],
  selectedYears: [2011, 2021],
  frequency: 12,
};

export const valueIsochroneFilterDefault = {
  isoChroneAddress: null,
  transportDuration: TRANSPORT_DURATION_MAX,
  transportMode: TRANSPORTMODE.find((item) => item.value === "car"),
};

const initialState = {
  loading: null,
  centerMap: {
    id: 1,
    coord: [46.483229, 2.660239],
    zoom: 6,
  },
  territoiresDuCiblage: [],
  communes: [],
  communesWithoutLayer: [],
  epci: [],
  nationalCode: null,
  valueTerritoire: {
    territoire: null,
    typologieAdminData: typologieAdminDataDefaultValue,
  },
  valueZoneEtudeZan: valueZoneEtudeDefaultValue,
  valueDefinitionEtude: valueDefinitionEtudeDefaultValue,
  valueSegmentation: "seleleted_segmentations",
  activeBtnNext: false,
  nextPage: "form_zone_etude_zan",
  currentPage: "form_territoire",
  zoneToEdit: null,
  createdAt: null,
  returnToEditTerritoire: false,
  valueIsochroneFilter: valueIsochroneFilterDefault,
  addresses: [],
};

export const formulaireCommandeSlice = createSlice({
  name: "formulaireCommande",
  initialState,
  reducers: {
    set_territoire_du_ciblage(state, action) {
      state.territoiresDuCiblage = action.payload;
    },
    set_value_territoire_territoire(state, action) {
      state.valueTerritoire = {
        territoire: action.payload,
        typologieAdminData: typologieAdminDataDefaultValue,
      };
      state.valueZoneEtudeZan = valueZoneEtudeDefaultValue;
      state.valueDefinitionEtude = valueDefinitionEtudeDefaultValue;
      state.returnToEditTerritoire = false;
      state.territoiresDuCiblage = [];
      state.epci = [];
      state.nationalCode = null;
      state.communesWithoutLayer = [];
      state.communes = [];
      state.valueIsochroneFilter = valueIsochroneFilterDefault;
      state.addresses = [];
    },
    set_communes(state, action) {
      state.communes = action.payload;
    },
    set_addresses(state, action) {
      // state.valueIsochroneFilter = valueIsochroneFilterDefault;
      state.addresses = action.payload;
    },
    setReturnToEditTerritoire(state, action) {
      state.returnToEditTerritoire = action.payload;
    },
    set_value_territoire(state, action) {
      state.valueTerritoire = action.payload;
    },
    set_value_selectedCommunes(state, action) {
      const current = transform(state.valueTerritoire);
      state.valueTerritoire = {
        ...current,
        typologieAdminData: {
          ...current.typologieAdminData,
          selectedCommunes: action.payload,
        },
      };
    },
    set_active_btn_next(state, action) {
      state.activeBtnNext = action.payload;
    },
    set_next_page(state, action) {
      state.nextPage = action.payload;
    },
    set_current_page(state, action) {
      state.currentPage = action.payload;
    },
    set_value_zone_etude_zan(state, action) {
      state.valueZoneEtudeZan = action.payload;
    },
    set_zone_to_edit(state, action) {
      state.zoneToEdit = action.payload;
    },
    set_value_definition_etude(state, action) {
      state.valueDefinitionEtude = action.payload;
    },
    set_value_segmentation(state, action) {
      state.valueSegmentation = action.payload;
    },
    set_center_map(state, action) {
      state.centerMap = action.payload;
    },
    set_created_at(state, action) {
      state.createdAt = action.payload;
    },
    set_epci(state, action) {
      state.epci = action.payload;
    },
    set_selected_communes(state, action) {
      const current = transform(state.valueTerritoire);
      state.valueTerritoire = {
        ...current,
        typologieAdminData: {
          ...current.typologieAdminData,
          selectedCommunes: action.payload,
        },
      };
    },
    set_communes_without_layer(state, action) {
      state.communesWithoutLayer = action.payload;
    },
    set_national_code(state, action) {
      state.nationalCode = action.payload;
    },
    set_value_isochorone(state, action) {
      state.valueIsochroneFilter = action.payload;
    },
    set_loading(state, action) {
      state.loading = action.payload;
    },
    reset: () => initialState,
  },
});

export const formulaireCommandeActions = formulaireCommandeSlice.actions;

export default formulaireCommandeSlice.reducer;
