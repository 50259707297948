import axios from 'axios';
import Rails from "@rails/ujs";

let config = {
    baseURL: process.env.BASE_URL,
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-CSRF-Token': Rails.csrfToken()
    },
};

let Api = axios.create(config);

export default Api
