export const TRANSPORTMODE = [
  { value: "walk", label: "A pied" },
  { value: "bike", label: "Vélo" },
  { value: "transit", label: "Transport en commun" },
  { value: "car", label: "Voiture" },
];

export const TRANSPORT_DURATION_MIN = 0;
export const TRANSPORT_DURATION_MAX = 45;

export const INDEX_DB_NAME = "territoireszan";
